import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useForm,
} from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
import pick from 'lodash/pick';
import values from 'lodash/values';
import isNil from 'lodash/isNil';
import {
  AddressDTO,
} from 'dtos';
import Controller from 'core/components/FormController';
import {
  ControllerRenderProps,
} from 'react-hook-form/dist/types/controller';
import {
  notEmptyString,
} from 'utils/misc';
import {
  validateZipCode,
  addressFields,
} from 'utils/react-hook-form';
import ZipCodeTextField from 'core/components/ZipCodeTextField';
import Input from 'pages/Dashboard/components/Input';
import Wrapper from 'pages/Dashboard/components/Input/Wrapper';
import USStateSelect from 'pages/Dashboard/components/USStateSelect';

type AddressForm = Pick<AddressDTO,
  'street'
  | 'unit'
  | 'city'
  | 'state'
  | 'zipCode'
  | 'country'
>;

type Props = {
  value?: AddressForm | null;
  onChange: (value: AddressDTO | null) => void;
  disabled?: boolean;
  onBlur?: () => void;
  required?: boolean;
};

export default function AddressFields({
  value,
  onChange,
  disabled,
  onBlur,
  required: _required = false,
}: Props) {
  const {
    control,
    watch,
    reset,
    trigger,
    formState: { isDirty },
  } = useForm<AddressForm>({ mode: 'onChange' });

  const formValue = watch();
  const [initialized, setInitialized] = useState<boolean>(false);

  const address = pick(value, addressFields);
  const required = useMemo<boolean>(
    () => _required || values(address).some(notEmptyString),
    [address, _required],
  );
  const isEmptyForm = useMemo(() => compact(values(formValue)).length === 0, [formValue]);

  useEffect(() => {
    if (isDirty) {
      reset(formValue, { keepErrors: true });
      onChange(formValue);
    }

    if (initialized) {
      trigger();
    }
  }, [isDirty, value, initialized]);

  // settings default values
  // in case default values weren't attached
  useEffect(() => {
    if (isEmptyForm && !initialized && !isEmpty(value)) {
      reset(value ?? {}, { keepDirty: false });
      setInitialized(true);
    }
  }, [value, isEmptyForm, initialized]);
  const handleOnBlur = (field: Partial<ControllerRenderProps<any, any>>) => () => {
    field?.onBlur?.();
    onBlur?.();
  };

  return (
    <div className="flex gap-3 w-full">
      <Controller
        name="street"
        control={control}
        rules={{ required }}
        render={({ field, fieldState: { error } }) => (
          <Input
            {...field}
            error={!isNil(error)}
            showErrorText={false}
            className="w-1/4"
            label="Street"
            placeholder="Street"
            disabled={disabled}
            required={required}
            id="street"
            onBlur={handleOnBlur(field)}
          />
        )}
      />
      <Controller
        name="unit"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            onBlur={handleOnBlur(field)}
            className="w-1/6"
            label="Unit"
            placeholder="Unit"
            disabled={disabled}
            id="address-unit"
          />
        )}
      />
      <Controller
        name="city"
        control={control}
        rules={{ required }}
        render={({ field, fieldState: { error } }) => (
          <Input
            {...field}
            error={!isNil(error)}
            showErrorText={false}
            className="w-1/4"
            label="City"
            placeholder="City"
            required={required}
            disabled={disabled}
            onBlur={handleOnBlur(field)}
            id="city"
          />
        )}
      />
      <Controller
        name="state"
        rules={{ required }}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Wrapper
            id="state"
            label="State"
            className="w-1/4"
            error={!isNil(error)}
            required={required}
            showErrorText={false}
          >
            <USStateSelect
              className="w-full"
              {...field}
              onBlur={handleOnBlur(field)}
            />
          </Wrapper>
        )}
      />
      <Controller
        name="zipCode"
        control={control}
        rules={{
          required,
          validate: validateZipCode,
        }}
        render={({ field, fieldState: { error } }) => (
          <Wrapper
            id="zip-code"
            label="Zip Code"
            className="w-1/6"
            showErrorText={false}
            required={required}
            error={!isNil(error)}
          >
            <ZipCodeTextField
              {...field}
              disabled={disabled}
              onBlur={handleOnBlur(field)}
            />
          </Wrapper>
        )}
      />
    </div>
  );
}
