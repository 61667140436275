import {
  useQuery,
  UseQueryResult,
} from 'react-query';
import {
  PrefetchedDataProps,
} from 'pages/Dashboard/types/common';

export default function usePrefetchedData<T>({
  key,
  fetchFn,
  enabled,
}: PrefetchedDataProps<T>): UseQueryResult<T, Error> {
  return useQuery<T, Error>(
    key,
    fetchFn,
    { cacheTime: Infinity, enabled },
  );
}
