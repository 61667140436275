import React, {
  lazy,
} from 'react';
import {
  Navigate,
  RouteObject,
} from 'react-router-dom';
import LazyLoadComponent from 'core/components/LazyLoadComponent';

export const encountersRoutes: RouteObject[] = [{
  path: '/encounters/:id',
  element: (
    <LazyLoadComponent Component={lazy(() => import('./pages/VisitNote'))} />
  ),
}, {
  path: '/encounters',
  element: (
    <LazyLoadComponent Component={lazy(() => import('./pages/List'))} />
  ),
}, {
  path: '*',
  element: <Navigate to="/encounters" />,
}];
