import React, {
  createContext,
  useMemo,
  useEffect,
  FC,
  useCallback,
} from 'react';
import {
  createPortal,
} from 'react-dom';
import {
  useForm,
  FieldValues,
  Path,
} from 'react-hook-form';
import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import Controller from 'core/components/FormController';
import MultiSelect from 'core/components/Multiselect';
import {
  FilterContextProps,
  FilterProviderProps,
  FieldFilterComparator,
  FieldAttributes,
  FieldType,
} from 'core/Filters/types';
import {
  objectAttributesMapping,
} from 'core/Filters/attributes';
import Wrapper from 'pages/Dashboard/components/Input/Wrapper';

function ComponentPlaceholder() {
  return <div />;
}

export const fieldComponents: Record<FieldType, FC<any>> = Object.freeze({
  multiselect: MultiSelect,
  select: ComponentPlaceholder,
  text: ComponentPlaceholder,
  flag: ComponentPlaceholder,
  range: ComponentPlaceholder,
  lookup: ComponentPlaceholder,
});

export const defaultComparator: FieldFilterComparator = (
  filterValues: any[],
  object: Record<string, any>,
  filterKey: string,
): boolean => filterValues.includes(object[filterKey]);

export const FilterContext = createContext<FilterContextProps>({
  data: [],
  filteredData: [],
  filters: {},
  setFilters: noop,
  isLoading: false,
  isError: false,
});

export default function FilterProvider<
  TFilter extends FieldValues, TData>({
  children,
  objectType,
  filtersSlot,
  persistToCache = false,
  dataParams = [],
  controlClassName,
  defaultValues,
}: FilterProviderProps) {
  const {
    getData,
    getMetaData,
    fields: _fields,
    normalizeMetaData,
    getObjectAccessor,
  } = objectAttributesMapping[objectType];

  const fields = useMemo(() => mapValues(
    _fields,
    ({ comparator, ...attributes }: FieldAttributes) => ({
      ...attributes,
      comparator: comparator ?? defaultComparator,
    }),
  ), [_fields]);

  const {
    data = [],
    refetch,
    isLoading,
    isError,
  } = getData(...dataParams);

  const metaData = normalizeMetaData(getMetaData());
  const cacheKey = `${objectType}_filters`;

  const {
    control, watch, reset, setValue,
  } = useForm<TFilter>();

  const filters = watch();
  const filterKeys = useMemo(() => keys(fields) ?? [], [fields]);
  const filteredData = useMemo(() => (data as TData[]).filter((item: TData) => {
    const object = getObjectAccessor(item);
    return filterKeys?.every((k) => (
      filters[k]?.length > 0 ? fields[k].comparator(filters[k], object, k, dataParams) : true
    ));
  }), [data, filters]);

  const clearAll = useCallback(() => {
    const defaultFilterValues = mapValues(
      fields,
      (attributes: FieldAttributes) => attributes.defaultValue,
    );
    reset(defaultFilterValues as TFilter);
  }, [fields, reset]);

  const props = useMemo(() => ({
    data: data as TData[],
    refetch,
    isLoading,
    isError,
    filters,
    filteredData,
    setFilters: setValue,
    clearAll,
  }), [data, isLoading, isError, refetch, filters, setValue]);

  useEffect(() => {
    if (persistToCache && !isNil(filtersSlot)) {
      localStorage.setItem(cacheKey, JSON.stringify(filters));
    }
  }, [persistToCache, filters, filtersSlot]);

  useEffect(() => {
    const cached = defaultValues ?? JSON.parse(localStorage.getItem(cacheKey) ?? '{}');
    const defaultFilterValues = mapValues(
      fields,
      (attributes: FieldAttributes) => attributes.defaultValue,
    );
    const values = isEmpty(cached) ? defaultFilterValues : cached;
    reset(values, { keepDirty: false, keepTouched: false });
  }, [defaultValues]);

  return (
    <FilterContext.Provider value={props}>
      {!isNil(filtersSlot) && createPortal(filterKeys?.map((filterKey: string) => (
        <Controller
          key={filterKey}
          control={control}
          name={filterKey as Path<TFilter>}
          render={({ field }) => {
            const fieldLabelKey = fields[filterKey]?.fieldLabelKey;
            const fieldValueKey = fields[filterKey]?.fieldValueKey;

            const getItemKey = !isNil(fieldValueKey)
              ? (option: any) => (!isNil(option) ? option[fieldValueKey] : '')
              : undefined;

            const getItemLabel = !isNil(fieldLabelKey)
              ? (option: any) => (!isNil(option) ? option[fieldLabelKey] : '')
              : undefined;

            const Component = fieldComponents[fields[filterKey].type];

            return (
              <Wrapper
                id={`filter-${fields[filterKey].label}`}
                label={fields[filterKey].label}
                className="w-full !space-y-1"
                labelClasses="text-xs"
              >
                <Component
                  {...field}
                  id={filterKey}
                  includeAll
                  hasClear
                  className={controlClassName}
                  getItemKey={getItemKey}
                  getItemLabel={getItemLabel}
                  options={metaData[filterKey] ?? []}
                  placeholder="Select"
                  renderOption={getItemLabel}
                  allCaption={fields[filterKey].allCaption}
                />
              </Wrapper>
            );
          }}
        />
      )), filtersSlot)}
      {children}
    </FilterContext.Provider>
  );
}
