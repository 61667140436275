import React, {
  createContext,
  useState,
  useMemo,
  useCallback,
  PropsWithChildren,
  useEffect,
} from 'react';
import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import keys from 'lodash/keys';
import noop from 'lodash/noop';
import {
  useQuery,
} from 'react-query';
import {
  MultiMediaResponse,
} from 'dtos';
import {
  getPatientProfileImages,
} from 'pages/Dashboard/utils/helper';

type MediaContextProps = {
  getMediaPathById: (id: number) => string | null;
  getMediaById: (id: number) => MultiMediaResponse | null;
  requestMedia: (ids: number[]) => void;
  invalidateMedia: (id: number, isDelete?: boolean) => void;
  isIdle: boolean;
};

export const MediaContext = createContext<MediaContextProps>({
  getMediaById: () => null,
  getMediaPathById: () => null,
  requestMedia: noop,
  invalidateMedia: noop,
  isIdle: false,
});

export default function MediaProvider({ children }: PropsWithChildren) {
  const [media, setMedia] = useState<Record<number, MultiMediaResponse>>({});
  const [ids, setIds] = useState<number[]>([]);

  const idsInUse = useMemo(() => new Set(keys(media)), [media]);

  const {
    data = [],
    isIdle,
    isFetching,
    refetch,
  } = useQuery<MultiMediaResponse[], Error>(
    [JSON.stringify(ids)],
    () => getPatientProfileImages(ids, { page: 0, pageSize: 100 }),
    { enabled: ids.length > 0 },
  );

  useEffect(() => {
    if (!isFetching && data?.length > 0) {
      const list: Record<number, MultiMediaResponse> = {};
      data.forEach((mediaResponse: MultiMediaResponse) => {
        list[mediaResponse?.originatorId ?? 0] = mediaResponse;
      });
      setMedia((media) => ({ ...media, ...list }));
    }
  }, [data, isFetching]);

  const getMediaPathById = useCallback((id: number) => (
    media[id]?.url?.value ?? null
  ), [media, ids]);
  const getMediaById = useCallback((id: number) => media[id] ?? null, [media, ids]);
  const requestMedia = useCallback((ids: number[]) => {
    setIds(ids.filter((id) => (!idsInUse.has(id.toString())) && id !== 0));
  }, [idsInUse]);

  const invalidateMedia = useCallback((id: number) => {
    setMedia(omit(media, [id]));
    setIds(uniq([...ids, id]));
    refetch();
  }, [media, ids]);

  const value = useMemo(() => ({
    getMediaById,
    getMediaPathById,
    requestMedia,
    invalidateMedia,
    isIdle,
  }), [isIdle, getMediaPathById, requestMedia, idsInUse]);

  return (
    <MediaContext.Provider value={value}>
      {children}
    </MediaContext.Provider>
  );
}
