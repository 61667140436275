import {
  useMutation,
} from 'react-query';
import {
  UserSettingDTO,
} from 'dtos';
import {
  getUserSettings,
  upsertUserSettings,
} from 'pages/Dashboard/services/api';
import usePrefetchedData from 'pages/Dashboard/hooks/usePrefetchedData';

export function useGetUserSettings() {
  const { data, isLoading } = usePrefetchedData<UserSettingDTO>({
    key: 'user-view-settings',
    defaultValue: {},
    fetchFn: getUserSettings,
  });

  return {
    data,
    isLoading,
  };
}

export function useUpsertUserSettings() {
  return useMutation<UserSettingDTO, Error, UserSettingDTO>(
    'user-view-settings',
    upsertUserSettings,
  );
}
