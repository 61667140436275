import React, {
  PropsWithChildren,
  useEffect,
} from 'react';
import Auth from 'pages/Auth';
import FullScreenSpinner from 'core/components/FullScreenSpinner';
import {
  useAuth,
} from 'core/hooks';

export default function ProtectedRouter({ children }: PropsWithChildren) {
  const {
    issueAccessToken,
    isAuthenticated,
    isLoading,
  } = useAuth();

  useEffect(() => {
    if (isAuthenticated) issueAccessToken();
  }, [isAuthenticated]);

  // Auth0 token fetching state
  if (isLoading) {
    return <FullScreenSpinner />;
  }

  // When auth0 is not authenticated, then it will show a auth page
  if (!isAuthenticated) {
    return <Auth />;
  }

  return children as JSX.Element;
}
