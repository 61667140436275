import React from 'react';
import ReactDOM from 'react-dom/client';
import EMRApp from 'views/EMR';
import IntakeApp from 'views/Intake';
import 'styles/index.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const isEMR = true;

root.render(isEMR ? <EMRApp /> : <IntakeApp />);
