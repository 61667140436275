import {
  Auth0ProviderOptions,
} from '@auth0/auth0-react';
import {
  auth0Audience as audience,
  auth0ClientId as clientId,
  auth0Domain as domain,
} from 'env';

export const auth0ProviderConfig: Auth0ProviderOptions = {
  domain,
  clientId,
  authorizationParams: {
    ...(audience ? { audience } : {}),
    redirect_uri: `${window.location.origin}/authorize`,
    scope: 'email',
  },
  useRefreshTokens: true,
};
