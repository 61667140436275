import {
  useState,
  useEffect,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';
import isNil from 'lodash/isNil';

function useUrlSyncState<T>(
  defaultValue: T,
  key: string,
  parseState: (val: T) => string,
  parseQuery: (val: string) => T,
  sessionStorageKey?: string,
): [T, Dispatch<SetStateAction<T>>] {
  const persistToSessionStorage = !isNil(sessionStorageKey);
  let storageValue = null;

  if (persistToSessionStorage) {
    storageValue = JSON.parse(sessionStorage.getItem(sessionStorageKey) ?? 'null');
  }

  const navigate = useNavigate();
  const { pathname, search = '' } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchParamsRef = useRef<URLSearchParams>(searchParams);
  const initialValue = searchParams.get(key) ?? storageValue ?? parseState(defaultValue);
  const parsedValue: T = parseQuery ? parseQuery(initialValue) : initialValue as T;
  const [value, dispatcher] = useState<T>(parsedValue);

  useEffect(() => {
    if (value) {
      const stringValue = parseState(value);
      searchParamsRef.current.set(key, stringValue);
      if (persistToSessionStorage) {
        sessionStorage.setItem(sessionStorageKey, JSON.stringify(stringValue));
      }
    } else {
      searchParamsRef.current.delete(key);
    }
    const queryString = searchParamsRef.current.toString();
    navigate(`${pathname}?${queryString}`, { replace: true });
  }, [value]);

  return [value, dispatcher];
}

export default useUrlSyncState;
