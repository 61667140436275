import {
  useEncounters,
} from 'pages/Dashboard/pages/Encounters/pages/List/hooks';
import {
  useEncounterSettings,
} from 'pages/Dashboard/pages/Encounters/hooks';
import useAppointmentsSettings, {
  preAppointmentStatusMapping,
} from 'pages/Dashboard/hooks/useAppointmentsSettings';
import {
  Appointment,
} from 'pages/Dashboard/pages/Appointments/pages/List/types/event';
import {
  FieldAttributes,
  FilterObjectType,
  ObjectAttributes,
  ObjectType,
} from 'core/Filters/types';
import {
  useAppointments,
} from 'pages/Dashboard/pages/Appointments/pages/List/hooks';
import {
  defaultComparator,
} from 'core/Filters/context';
import {
  DecimalNullableRangeFilterDTO,
} from 'dtos/decimalNullableRangeFilterDTO';
import {
  FilterableSettingDTO,
} from 'dtos/filterableSettingDTO';
import {
  useChargesFilterMetaComponents,
} from 'pages/Dashboard/pages/Billing/Charges/hooks';
import {
  useToDosFilterMetadata,
} from 'pages/Dashboard/pages/Todos/hooks';
import {
  useGetPatientPaymentFilterSettings,
} from 'pages/Dashboard/pages/Billing/hooks';
import {
  useGetEFaxFilterMetaComponents,
} from 'pages/Dashboard/pages/Fax/hooks';
import {
  EncounterViewModel,
} from 'dtos';

const officeLocationFilter: FieldAttributes = {
  label: 'Location',
  allCaption: 'All Locations',
  fieldValueKey: 'addressId',
  fieldLabelKey: 'nickName',
  defaultValue: [],
  type: 'multiselect',
  comparator: (
    filterValues,
    object,
    filterKey,
    [, viewType] = [],
  ) => viewType === 'Monthly' || filterValues.some(
    (id) => object?.officeLocation?.addressId === id || object?.officeLocationId === id,
  ),
};

// @TODO
// when it will be over 3 objects then
// move each object attributes definition to a separate file
export const objectAttributesMapping: Record<ObjectType, ObjectAttributes> = {
  encounter: {
    getData: useEncounters,
    getMetaData: useEncounterSettings,
    getObjectAccessor: (encounter: EncounterViewModel) => encounter,
    normalizeMetaData: (meta: Record<string, string[]>) => ({
      userId: meta.doctors ?? [],
      typeOfVisitDescription: meta.appointmentTypes,
      patientAppointmentStatus: meta.appointmentStatuses ?? [],
      officeLocation: meta.officeLocation ?? [],
    }),
    fields: {
      userId: {
        defaultValue: [],
        label: 'Doctors',
        allCaption: 'All Doctors',
        fieldValueKey: 'userId',
        fieldLabelKey: 'name',
        type: 'multiselect',
      },
      typeOfVisitDescription: {
        label: 'Visit Types',
        allCaption: 'All Visits',
        defaultValue: [],
        type: 'multiselect',
      },
      patientAppointmentStatus: {
        label: 'Appointment Statuses',
        allCaption: 'All Appointments',
        defaultValue: [],
        type: 'multiselect',
      },
      officeLocation: officeLocationFilter,
    },
  },
  appointment: {
    getData: useAppointments,
    getMetaData: useAppointmentsSettings,
    getObjectAccessor: (appointment: Appointment) => appointment,
    normalizeMetaData: (meta: Record<string, any[]>) => ({
      userId: meta.doctors ?? [],
      typeOfVisitDescription: meta.appointmentTypes ?? [],
      patientAppointmentStatus: meta.appointmentStatuses ?? [],
      patientPreAppointmentStatus: meta.patientPreAppointmentStatus ?? [],
      officeLocation: meta.officeLocation ?? [],
    }),
    fields: {
      userId: {
        defaultValue: [],
        label: 'Doctors',
        allCaption: 'All Doctors',
        fieldValueKey: 'userId',
        fieldLabelKey: 'name',
        type: 'multiselect',
      },
      typeOfVisitDescription: {
        label: 'Appointment Types',
        allCaption: 'All Appointments',
        defaultValue: [],
        type: 'multiselect',
      },
      patientAppointmentStatus: {
        label: 'Appointment Statuses',
        allCaption: 'All Visits',
        defaultValue: [],
        type: 'multiselect',
        comparator: (filterValues, object, filterKey, [, viewType] = []) => {
          if (viewType === 'Monthly') return true;
          return defaultComparator(filterValues, object, filterKey);
        },
      },
      patientPreAppointmentStatus: {
        defaultValue: [],
        label: 'Patient Filter',
        allCaption: 'All Patients',
        fieldValueKey: 'id',
        fieldLabelKey: 'caption',
        comparator: (filterValues, object, filterKey, [, viewType] = []) => {
          if (viewType === 'Monthly') return true;

          const rescheduledFilters: boolean[] = [];
          const intakeFilters: boolean[] = [];

          filterValues.forEach((valueId) => {
            if (preAppointmentStatusMapping[valueId].isSchedule) {
              rescheduledFilters.push(preAppointmentStatusMapping[valueId].value);
            } else {
              intakeFilters.push(preAppointmentStatusMapping[valueId].value);
            }
          });

          return (rescheduledFilters.includes(object.canBeRescheduledSooner ?? false) ?? true)
            || (intakeFilters.includes(object?.intakeFormCompleted ?? false) ?? true);
        },
        type: 'multiselect',
      },
      officeLocation: officeLocationFilter,
    },
  },
};

export type FilterAttributes = {
  getComponents: () => FilterableSettingDTO[];
};

export const filterMap: Record<FilterObjectType, FilterAttributes> = {
  charges: {
    getComponents: useChargesFilterMetaComponents,
  },
  toDos: {
    getComponents: useToDosFilterMetadata,
  },
  patientPayments: {
    getComponents: useGetPatientPaymentFilterSettings,
  },
  eFax: {
    getComponents: useGetEFaxFilterMetaComponents,
  },
};

export type MergedFilterValueType = DecimalNullableRangeFilterDTO & FilterableSettingDTO;
