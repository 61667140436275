import React, {
  useEffect,
  useState,
} from 'react';
import isNil from 'lodash/isNil';
import clsx from 'clsx';
import {
  TaskIcon,
} from 'core/icons';
import {
  useSignalREffect,
  useUserToDosCount,
} from 'views/EMR/hooks';
import Badge from 'core/components/Badge';

type Props = {
  className?: string;
  data?: { expanded?: boolean };
};
export default function ToDosSidebarItem({
  className,
  data: sidebarProps,
}: Props) {
  const [count, setCount] = useState<number>(0);
  const {
    data: initialData,
    isLoading,
  } = useUserToDosCount();

  useSignalREffect('OnUnreadToDoCountUpdated', (receivedCount: number) => {
    setCount(Number(receivedCount));
  });

  useEffect(() => {
    if (!isNil(initialData) && !isLoading) {
      setCount(Number(initialData?.toDoCount) ?? 0);
    }
  }, [initialData]);

  return (
    <Badge
      badgeContent={count}
      color="info"
      invisible={sidebarProps?.expanded}
      Icon={TaskIcon}
      classes={{
        icon: className,
        badge: 'flex w-full items-center',
      }}
    >
      <div className={clsx(
        'text-xs opacity-1 truncate transition-opacity duration-75 delay-100 ease-out ml-2.5 mr-20',
        { 'opacity-0 text-[0px] collapse': !sidebarProps?.expanded },
      )}
      >
        To-dos
      </div>
      <Badge
        badgeContent={count}
        color="info"
        invisible={!sidebarProps?.expanded}
        classes={{ badge: 'mr-2' }}
        sx={{
          '.MuiBadge-badge': {
            fontSize: '0.75rem',
            minWidth: '1.25rem !important',
            height: '1.25rem !important',
          },
        }}

      />
    </Badge>
  );
}
