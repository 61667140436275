import React, {
  forwardRef,
  ForwardedRef,
  RefObject,
  useEffect,
  useState,
  ReactNode,
} from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import uniqueId from 'lodash/uniqueId';
import isNil from 'lodash/isNil';
import Tooltip, {
  TooltipProps,
} from '@mui/material/Tooltip';
import clsx from 'clsx';

export type Props = Pick<TooltipProps,
  'className'
  | 'classes'
  | 'placement'
  | 'disableFocusListener'
  | 'children'
> & {
  popperClassName?: string;
  title?: string | ReactNode;
  theme?: 'dark' | 'light';
  hasIcon?: boolean;
  hide?: boolean;
  auto?: boolean;
  open?: boolean;
  disablePortal?: boolean;
  isInteractive?: boolean;
  offset?: [skidding: number, distance: number];
};

function checkIfTextOverflowing(element: HTMLElement | null) {
  if (isNil(element)) return false;

  return Number(element.offsetWidth) < Number(element.scrollWidth);
}

const resizeHandlerRegistry: Record<string, (overflown: boolean) => void> = {};
const ToolTipResizeObserver = new ResizeObserver((entries) => {
  if (!Array.isArray(entries)) return;

  window.requestAnimationFrame(() => {
    entries.forEach((entry: ResizeObserverEntry) => {
      const element = entry.target as HTMLElement;
      const uniqId = element.dataset.populateTooltipId ?? '';
      const handler = resizeHandlerRegistry[uniqId];

      if (typeof handler === 'function') {
        handler(checkIfTextOverflowing(element));
      }
    });
  });
});

const ToolTip = forwardRef(({
  className,
  popperClassName,
  title = 'Quick Tip',
  children,
  placement = 'top',
  theme = 'light',
  auto = false,
  hide,
  disablePortal = false,
  isInteractive = false,
  offset = [0, 0],
  disableFocusListener = true,
  classes: _classes,
  open,
}: Props, ref: ForwardedRef<HTMLElement>) => {
  const [overflowed, setOverflowed] = useState<boolean>(false);
  const classes = clsx('flex w-full justify-start items-center text-xs', className);
  const popperClasses = clsx(popperClassName, { 'tooltip-theme-light': theme === 'light' });

  useEffect(() => {
    const isOverflowed = checkIfTextOverflowing((ref as RefObject<HTMLElement>)?.current);
    setOverflowed(isOverflowed);
  }, []);

  useEffect(() => {
    const element = (ref as RefObject<HTMLElement>)?.current;
    if (isNil(element) || !auto) return () => {};

    const uniqId = uniqueId('tooltip-id-');
    element.dataset.populateTooltipId = uniqId;
    resizeHandlerRegistry[uniqId] = setOverflowed;
    ToolTipResizeObserver.observe(element);

    return () => {
      delete resizeHandlerRegistry[element.dataset.populateTooltipId ?? ''];
      ToolTipResizeObserver.unobserve(element);
    };
  }, [ref, auto]);

  const isHidden = auto ? !overflowed : hide;

  return (
    <Tooltip
      classes={{
        ..._classes,
        popper: clsx(_classes?.popper, popperClasses),
      }}
      title={(<div className={classes}>{title}</div>)}
      enterNextDelay={100}
      placement={placement}
      arrow
      disableFocusListener={disableFocusListener}
      open={open}
      disableHoverListener={isHidden}
      disableTouchListener={isHidden}
      disableInteractive={!isInteractive}
      PopperProps={{ disablePortal }}
      slotProps={{
        popper: {
          modifiers: [{
            name: 'offset',
            options: { offset },
          }],
        },
      }}
    >
      {children}
    </Tooltip>
  );
});

export default ToolTip;
