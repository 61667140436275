import {
  settingsRoutes,
} from 'views/EMR/Settings/routes';

export default [{
  title: 'Settings',
  name: 'Settings',
  href: 'settings',
  subRoutes: settingsRoutes,
}, {
  name: 'Notifications',
  href: 'notifications',
}, {
  name: 'Profile',
  title: 'Profile',
  href: 'profile',
}];
