import React from 'react';
import {
  Auth0Provider,
} from '@auth0/auth0-react';
import {
  ThemeProvider,
} from '@mui/material/styles';
import {
  auth0ProviderConfig,
} from 'config/auth';
import {
  theme,
} from 'config/mui';
import 'config/sentry';
import 'config/mixpanel';
import ErrorBoundary from 'pages/Dashboard/components/ErrorBoundary';
import ReactQueryProvider from 'core/components/ReactQueryProvider';
import {
  RouterProvider,
} from 'react-router-dom';
import router from 'views/EMR/router';
import {
  SnackbarProvider,
} from 'notistack';
import Notification from 'core/components/Notification';

export default function EMRApp() {
  return (
    <ErrorBoundary>
      <Auth0Provider {...auth0ProviderConfig}>
        <SnackbarProvider Components={{ custom: Notification }}>
          <ReactQueryProvider>
            <ThemeProvider theme={theme}>
              <div className="h-full w-full">
                <RouterProvider router={router} />
              </div>
            </ThemeProvider>
          </ReactQueryProvider>
        </SnackbarProvider>
      </Auth0Provider>
    </ErrorBoundary>
  );
}
