import React, {
  FunctionComponent,
  LazyExoticComponent,
  Suspense,
} from 'react';
import FullScreenSpinner from 'core/components/FullScreenSpinner';

type Props = {
  Component: LazyExoticComponent<FunctionComponent>;
};

export default function LazyLoadComponent({ Component }: Props) {
  return (
    <Suspense fallback={<FullScreenSpinner />}>
      <Component />
    </Suspense>
  );
}
