import isFunction from 'lodash/isFunction';
import {
  postApiToDoRequestToDoList,
} from 'endpoints';
import {
  request,
} from 'services/api';
import {
  ToDo,
  CommentActivity,
} from 'types/toDo';
import {
  CommentResponsePayload,
  UpdateToDoRequestPayload,
  UpdateToDoResponsePayload,
  CommentActivityResponse,
  ToDoSettingsResponse,
  TodoDataParams,
  ToDoResponse,
} from 'pages/Dashboard/pages/Todos/types';
import {
  normalizeToDos,
  normalizeToDo,
  normalizeCommentActivity,
} from 'pages/Dashboard/pages/Todos/normalize';
import {
  formatISODateTime,
} from 'utils/date';

export const createToDo = (data: Partial<ToDo>): Promise<ToDo> => request<ToDo>('ToDo/CreateToDo', {
  method: 'POST',
  data,
});

export const commentOnToDo = (data: Partial<ToDo>): Promise<CommentResponsePayload> => (
  request<CommentResponsePayload>('ToDo/CommentOnToDo', {
    method: 'POST',
    data,
  })
);

export const getDetailedToDo = (toDoId: number): Promise<ToDo> => (
  request<ToDoResponse>(`ToDo/GetToDoById/?toDoId=${toDoId}&includeReferencedObjects=true`, { method: 'GET' }).then(
    ({ users, patients, ...response }: ToDoResponse) => (
      normalizeToDo(response, users, patients)
    ),
  )
);

export const getToDoComments = (toDoId: number): Promise<CommentActivity[]> => (
  request<CommentActivityResponse>(`ToDo/GetCommentsByToDoId/?toDoId=${toDoId}&includeReferencedObjects=true`, { method: 'GET' })
    .then(normalizeCommentActivity)
);

export const getToDoSettings = (): Promise<ToDoSettingsResponse> => (
  request<ToDoSettingsResponse>(
    'Settings/GetToDoSettings',
    { method: 'GET' },
  )
);

export const updateToDo = (data: UpdateToDoRequestPayload): Promise<UpdateToDoResponsePayload> => (
  request<UpdateToDoResponsePayload>('ToDo/UpdateToDo', {
    method: 'PUT',
    data,
  })
);

export const getToDosData = async ({
  pagination,
  dateRange,
  setTotalSize,
  requestMedia,
  filterQuery,
  searchQuery,
  patientId,
}: TodoDataParams): Promise<ToDo[]> => {
  const [start, end] = (dateRange ?? []).map((date) => formatISODateTime(date));
  const response = await postApiToDoRequestToDoList({
    includeReferencedObjects: true,
    patientSearchText: searchQuery,
    dateRange: { start, end },
    filterQuery,
    patientId,
    pageRequest: {
      page: pagination?.pageIndex ?? 0,
      pageSize: pagination?.pageSize ?? 25,
    },
  });
  if (isFunction(requestMedia)) {
    const ids = response.toDos?.data?.map(({ patientId }) => Number(patientId)) ?? [];
    requestMedia(ids);
  }

  const meta = response.toDos?.pageProperties;
  setTotalSize(meta?.totalSize ?? 0);

  const normalize = normalizeToDos(response);

  return normalize;
};
