import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import {
  SvgIconProps,
} from '@mui/material/SvgIcon/SvgIcon';

export default function DeleteIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      viewBox="0 0 20 20"
      {...props}
      fill="none"
      htmlColor="transparent"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8676 6.47827C12.8676 6.47827 12.5056 10.9683 12.2956 12.8596C12.1956 13.7629 11.6376 14.2923 10.7236 14.3089C8.98422 14.3403 7.24289 14.3423 5.50422 14.3056C4.62489 14.2876 4.07622 13.7516 3.97822 12.8643C3.76689 10.9563 3.40689 6.47827 3.40689 6.47827"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7896 4.32601H2.48425"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6111 4.326C11.0878 4.326 10.6371 3.956 10.5345 3.44333L10.3725 2.63267C10.2725 2.25867 9.93381 2 9.54781 2H6.72581C6.33981 2 6.00114 2.25867 5.90114 2.63267L5.73914 3.44333C5.63648 3.956 5.18581 4.326 4.66248 4.326"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
