import React, {
  ForwardedRef,
  forwardRef,
} from 'react';
import {
  SnackbarContent,
  CustomContentProps,
  SnackbarKey,
} from 'notistack';
import Alert from '@mui/material/Alert';
import {
  AlertVariant,
} from 'pages/Dashboard/components/Alert';

export interface NotifyProps extends CustomContentProps {
  className?: string;
  dismissible: boolean;
  handleClose: (id: SnackbarKey) => void;
  severeity: AlertVariant;
  icon?: React.ReactNode;
}

function Notification({
  message,
  severeity,
  handleClose: _handleClose,
  id,
  dismissible,
  className,
  icon,
}: NotifyProps, ref: ForwardedRef<HTMLDivElement>) {
  const handleClose = () => _handleClose(id);

  return (
    <SnackbarContent ref={ref} role="alert">
      <Alert
        className={className}
        variant="standard"
        severity={severeity}
        onClose={dismissible ? handleClose : undefined}
        icon={icon}
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </SnackbarContent>
  );
}

export default forwardRef(Notification);
