import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  unstable_useBlocker as useBlocker,
} from 'react-router-dom';
import {
  useAuth0,
} from '@auth0/auth0-react';
import noop from 'lodash/noop';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import mixpanel from 'mixpanel-browser';
import {
  auth0ClientId,
  environment,
} from 'env';
import {
  generateTokenItem,
  getAccessTokenFromLocalStorage,
  getIsTokenItemValid,
  setAccessTokenToLocalStorage,
  setHeaders,
} from 'utils/auth';
import {
  EventBus,
  EventMap,
} from 'core/EventBus';
import {
  SelectableContext,
} from 'core/context/selectable';
import {
  MediaContext,
} from 'core/context/media';
import {
  VirtualTableInstance,
} from 'core/components/VirtualTable/types';
import {
  notEmptyString,
} from 'utils/misc';
import {
  ConfirmationDialogContext,
} from 'pages/Dashboard/contexts/confirmationDialog';
import useUserInfo from 'pages/Dashboard/hooks/useUserInfo';
import {
  UserSettingsContext,
} from 'pages/Dashboard/contexts/userSettings';
import {
  SidePanelContext,
} from 'pages/Dashboard/contexts/sidePanel';
import {
  FilterInstance,
  FilterObjectType,
} from 'core/Filters/types';
import {
  filterComponentsByType,
  serializeFilterQuery,
} from 'core/Filters/util';
import {
  DateRangeParams,
  useSyncDateRange,
} from 'pages/Dashboard/hooks/url';
import {
  useSnackbar,
  SnackbarKey,
} from 'notistack';
import {
  AlertVariant,
} from 'pages/Dashboard/components/Alert';
import {
  NotifyProps,
} from 'core/components/Notification';

// @TODO
// If file will exceed 300 lines code,
// let's split it into multiple files under hooks directory,
// where hooks will be grouped together logically

export type NotificationProps = {
  message?: string | ReactNode;
  variant?: AlertVariant;
  timeoutInMS?: number;
  dismissible?: boolean;
  onClose?: () => void;
};

export function useNotify() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const notify = ({
    message = 'try this notification',
    timeoutInMS = 10 * 1000,
    dismissible = true,
    variant = AlertVariant.INFO,
    onClose,
    ...rest
  }: NotificationProps) => {
    const options: Partial<NotifyProps> = {
      ...rest,
      autoHideDuration: timeoutInMS,
      variant: 'custom',
      severeity: variant,
      dismissible,
      handleClose: (id: SnackbarKey) => {
        closeSnackbar(id);
        onClose?.();
      },
      anchorOrigin: { horizontal: 'center', vertical: 'top' },
    };
    enqueueSnackbar(message, options);
  };
  return notify;
}

export function useMedia() {
  return useContext(MediaContext);
}

export function useSelectableContext() {
  return useContext(SelectableContext);
}

export function useConfirmDialog() {
  const { openDialog, closeDialog } = useContext(ConfirmationDialogContext);
  return {
    confirm: openDialog,
    closeConfirm: closeDialog,
  };
}

type PromptProps = {
  title?: string;
  message?: string | ReactNode;
  onLeave?: () => void;
  onStay?: () => void;
};

export function usePrompt(when: boolean, {
  title,
  message,
  onLeave,
  onStay,
}: Partial<PromptProps> = {}) {
  const blocker = useBlocker(when);
  const { confirm, closeConfirm } = useConfirmDialog();

  useEffect(() => {
    if (blocker?.state === 'blocked') {
      confirm({
        className: '!w-[32rem]',
        disableBackdropClick: true,
        hideCloseBtn: true,
        title: title ?? 'Leave Page?',
        content:
          isNil(message)
            ? (
              <div className="!mt-0">
                You have unsaved changes. Do you want to leave without saving?
              </div>
            )
            : message,
        confirmText: 'Stay',
        cancelText: 'Leave',
        onConfirm: () => {
          onStay?.();
          blocker.reset?.();
        },
        onClose: () => {
          blocker.proceed?.();
          onLeave?.();
          closeConfirm();
        },
      });
    } else {
      closeConfirm();
    }
  }, [blocker]);
}

export function useMixpanelTrack() {
  if (environment !== 'prod') return noop;

  const userInfo = useUserInfo();
  const { user, organization } = userInfo ?? {};

  return (eventName: string, properties?: Record<string, any>) => {
    mixpanel.track(eventName, {
      ...(properties ?? {}),
      userTitle: user?.title,
      userId: user?.userId,
      orgId: organization?.organizationId,
    });
  };
}

export function useUserSettings() {
  const userSettings = useContext(UserSettingsContext);
  return userSettings;
}

export function useSidePanel() {
  const { openSidePanel, closeSidePanel } = useContext(SidePanelContext);
  return { openSidePanel, closeSidePanel };
}

export function useLogoutFromApp() {
  const { logout } = useAuth0();
  return async () => {
    await logout({
      clientId: auth0ClientId,
      logoutParams: {
        federated: true,
        returnTo: window.location.origin,
      },
    });

    setHeaders('');
    localStorage.clear();
    sessionStorage.clear();
  };
}

export function useAuth() {
  const {
    isAuthenticated,
    getAccessTokenSilently,
    isLoading,
    loginWithRedirect,
    handleRedirectCallback,
  } = useAuth0();
  const logout = useLogoutFromApp();

  const issueAccessToken = useCallback(async (): Promise<string | null> => {
    const cachedTokenItem = getAccessTokenFromLocalStorage();
    const cachedTokenIsExpired = !getIsTokenItemValid(cachedTokenItem) && isAuthenticated;

    try {
      const token = await (cachedTokenIsExpired
        ? getAccessTokenSilently()
        : Promise.resolve(cachedTokenItem.token)
      );

      if (cachedTokenIsExpired) {
        setAccessTokenToLocalStorage(generateTokenItem(token));
      }

      setHeaders(token);
      return token;
    } catch (e) {
      console.error(e);
      logout();
    }

    return null;
  }, [isAuthenticated, getAccessTokenSilently, loginWithRedirect]);

  return {
    issueAccessToken,
    isAuthenticated,
    isLoading,
    logout,
    loginWithRedirect,
    handleRedirectCallback,
  };
}

type EventSubscription<T extends EventMap> = {
  channel: EventBus<T>;
  event: keyof T;
};

type UseTableResetProps<T extends EventMap> = {
  changeSubscription?: EventSubscription<T>;
};

export function useTableReset<T extends EventMap>(
  { changeSubscription }: UseTableResetProps<T> = {},
) {
  const {
    channel,
    event,
  } = changeSubscription ?? {};
  const tableInstanceRef = useRef<VirtualTableInstance>();

  useEffect(() => {
    const unsubscribe = (!isEmpty(changeSubscription) && notEmptyString(event))
      ? channel?.on?.(event as keyof T, (() => {
        tableInstanceRef?.current?.refetch?.();
      }) as T[keyof T])
      : noop;
    return () => {
      unsubscribe?.();
    };
  }, []);

  const reset = () => {
    const { pagination, setPageIndex } = tableInstanceRef?.current ?? {};
    if (pagination?.pageIndex !== 0 && isFunction(setPageIndex)) {
      setPageIndex(0);
    }
  };

  return {
    tableInstanceRef,
    reset,
  };
}

export function useFilterQuery<T>(
  objectType: FilterObjectType,
  dateRangeParams?: DateRangeParams,
) {
  const filterInstanceRef = useRef<FilterInstance>();

  const { settings } = useUserSettings();
  const { componentsByName } = filterComponentsByType(objectType);

  const defaultValues = serializeFilterQuery(
    settings[`${objectType}Filters`],
    componentsByName,
  );
  const [filterQuery, setFilterQuery] = useState<T>(defaultValues as T);

  const [dateRange, setDateRange] = useSyncDateRange(dateRangeParams ?? {});

  return {
    filterQuery,
    setFilterQuery,
    filterInstanceRef,
    dateRange,
    setDateRange,
  };
}
