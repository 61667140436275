import memoize from 'lodash/memoize';
import forEach from 'lodash/forEach';
import random from 'lodash/random';
import values from 'lodash/values';
import flatMap from 'lodash/flatMap';
import colors from 'overrides/colors';

const flatColors: Record<string, string> = {};
forEach(colors, (tones, colorKey) => {
  if (typeof tones === 'string') return;

  forEach(tones, (color, toneKey) => {
    flatColors[`${colorKey}-${toneKey}`] = color ?? '#787878';
  });
});
export const getColorByName = memoize(
  (colorName?: string, defaultColor: string = 'gray-200') => (
    flatColors[colorName ?? 'gray-200'] ?? flatColors[defaultColor]
  ),
);

const colorList: string[] = flatMap(
  colors,
  (colorsByTone: string | Record<string, string>, k) => (
    typeof colorsByTone !== 'string' && !k.includes('populate')
      ? values(colorsByTone).slice(4) : []
  ),
);
export const getColorForUnique = memoize((unique: string | null): string => {
  const index = random(0, colorList.length - 1);
  return colorList[index];
});
