import {
  useQuery,
  UseQueryResult,
} from 'react-query';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import values from 'lodash/values';
import {
  NPISearchItemDTO,
  ProviderDTO,
} from 'dtos';
import {
  getProviders,
  getReferringProviderFromLastVisit,
} from 'pages/Dashboard/services/api';
import {
  NormalizedUser,
} from 'pages/Dashboard/pages/Appointments/pages/List/types/event';
import {
  getProvidersByParams,
} from 'pages/Dashboard/pages/Billing/api';
import {
  ProvidersSearchParams,
} from 'pages/Dashboard/pages/Billing/types';

// @TODO: rename that hook to be useUsers later
export function useProviders(onlyDoctors = true): UseQueryResult<NormalizedUser[], Error> {
  return useQuery<NormalizedUser[], Error>(
    `doctorList/${onlyDoctors}`,
    () => getProviders(onlyDoctors),
  );
}

export function useProvidersSearch(
  queryParams: ProvidersSearchParams | null,
): UseQueryResult<NPISearchItemDTO[], Error> {
  return useQuery<NPISearchItemDTO[], Error>(
    ['providers-search', values(queryParams)],
    async () => {
      const response = await (isNil(queryParams)
        ? Promise.resolve({ result: [] })
        : getProvidersByParams(queryParams)
      );

      return response?.result ?? [];
    },
    { enabled: !isNil(queryParams) && !isEmpty(queryParams) },
  );
}

export function useReferringProviderFromLastVisit(
  patientId: number = 0,
): UseQueryResult<ProviderDTO | null, Error> {
  return useQuery<ProviderDTO | null, Error>(
    ['last-visit-rp', patientId],
    async () => {
      const result = await getReferringProviderFromLastVisit(patientId);
      return result instanceof Object ? result : null;
    },
    { enabled: patientId > 0 },
  );
}
