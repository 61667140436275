import React, {
  forwardRef,
} from 'react';
import type {
  ForwardedRef,
  MouseEvent,
  ReactNode,
} from 'react';
import clsx from 'clsx';
import MuiButton from '@mui/material/Button';

export type Props = {
  className?: string;
  type?: 'button' | 'submit';
  variant: 'text' | 'outlined' | 'contained';
  color?: 'inherit' | 'primary' | 'secondary' | 'error';
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  onMouseDown?: (event: MouseEvent<HTMLButtonElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  disabled?: boolean;
  href?: string;
  children: ReactNode;
  endIcon?: ReactNode;
  disableElevation?: boolean;
  disableRipple?: boolean;
  tabIndex?: number;
};

const Button = forwardRef(({
  className,
  type = 'button',
  variant,
  color = 'inherit',
  onClick,
  onFocus,
  onBlur,
  disabled,
  href,
  children,
  endIcon,
  disableElevation = false,
  disableRipple = false,
  ...props
}: Props, ref: ForwardedRef<HTMLButtonElement>) => {
  const btnClasses = {
    text: 'btn-text',
    outlined: 'btn-outlined',
    contained: 'btn-contained',
  };
  const btnColors: Record<string, 'primary' | 'secondary' | 'error'> = {
    primary: 'secondary',
    secondary: 'primary',
    error: 'error',
  };
  const classes = clsx(
    'btn',
    className,
    btnClasses[variant],
  );

  return (
    <MuiButton
      className={classes}
      type={type}
      variant={variant}
      color={btnColors[color] || 'inherit'}
      onClick={onClick}
      disabled={disabled}
      onFocus={onFocus}
      onBlur={onBlur}
      href={href}
      ref={ref}
      endIcon={endIcon}
      disableElevation={disableElevation}
      disableRipple={disableRipple}
      {...props}
    >
      {children}
    </MuiButton>
  );
});

export default Button;
