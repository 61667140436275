import React, {
  lazy,
} from 'react';
import {
  RouteObject,
} from 'react-router-dom';
import LazyLoadComponent from 'core/components/LazyLoadComponent';

export const faxRoutes: RouteObject[] = [{
  path: '/fax',
  element: (
    <LazyLoadComponent Component={lazy(() => import('./List'))} />
  ),
}, {
  path: '/fax/contacts',
  element: (
    <LazyLoadComponent Component={lazy(() => import('../Contacts'))} />
  ),
}];
