import React, {
  MouseEvent,
} from 'react';
import IconButton from '@mui/material/IconButton';
import ToolTip from 'core/components/Tooltip';
import Spinner from 'pages/Dashboard/components/Spinner';
import MicIcon from 'core/icons/mic';
import clsx from 'clsx';

type Props = {
    loading?: boolean;
    listening?: boolean;
    onFocus?: () => void;
    onBlur?: () => void;
    onClick?: (e?: MouseEvent<HTMLButtonElement>) => void;
    micClasses?: string;
};

export default function Mic({
  listening,
  loading,
  onClick,
  onFocus,
  onBlur,
  micClasses,
}: Props) {
  return (
    <ToolTip title={listening ? 'Stop Dictation' : 'Start Dictation'}>
      <span>
        <IconButton
          className={micClasses}
          onFocus={onFocus}
          onBlur={onBlur}
          onClick={onClick}
        >
          {loading ? (
            <Spinner size={16} />
          ) : (
            <MicIcon
              className={clsx('text-gray-light !h-4 !w-4', {
                'text-red-600': listening,
              })}
            />
          )}
        </IconButton>
      </span>
    </ToolTip>
  );
}
