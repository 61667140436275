import React, {
  ChangeEvent,
  FocusEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  ReactNode,
  RefObject,
} from 'react';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import Wrapper from 'pages/Dashboard/components/Input/Wrapper';

type Part = 'root' | 'container' | 'input' | 'label';

type Props = {
  className?: string;
  classes?: Partial<Record<Part, string>>;
  labelClasses?: string;
  inputClasses?: string;
  readOnlyValueClassName?: string;
  type?: string;
  id?: string;
  label?: string | ReactNode;
  value?: string | number | null;
  defaultValue?: string | number | null;
  placeholder?: string;
  onChange?: (val: string, event?: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onClick?: MouseEventHandler<HTMLElement>;
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onKeyDown?: KeyboardEventHandler<HTMLElement>;
  required?: boolean;
  inputProps?: Record<string, any>;
  allowSpeechToText?: boolean;
  disabled?: boolean;
  nonEditable?: boolean;
  icon?: ReactNode;
  endIcon?: ReactNode;
  inputRef?: RefObject<any>;
  sx?: object;
  error?: boolean;
  showErrorText?: boolean;
  trackingLabel?: string;
};

export default function Input({
  className,
  labelClasses,
  inputClasses,
  readOnlyValueClassName,
  id = '',
  label,
  value,
  placeholder,
  onChange,
  required,
  allowSpeechToText,
  disabled,
  nonEditable,
  icon,
  endIcon,
  type,
  sx,
  inputProps,
  inputRef,
  onBlur,
  onClick,
  onKeyDown,
  onFocus,
  defaultValue,
  error,
  showErrorText = true,
  trackingLabel,
  classes,
}: Props) {
  const textInputClasses = clsx(
    'populate-input',
    { 'no-border': error },
    inputClasses,
    classes?.input,
  );

  return (
    <Wrapper
      id={id}
      label={label}
      className={clsx(className, classes?.root)}
      micClassName="!absolute"
      labelClasses={clsx(labelClasses, classes?.label)}
      containerClassName={clsx({ 'h-full items-center px-4 border rounded border-gray-200': nonEditable }, classes?.container)}
      required={required}
      error={error}
      showErrorText={showErrorText}
      allowSpeechToText={allowSpeechToText && !nonEditable && !disabled}
      onChange={onChange}
      trackingLabel={trackingLabel}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
    >
      {nonEditable ? (
        <p className={clsx('text-xs', readOnlyValueClassName)}>
          {value}
        </p>
      ) : (
        <TextField
          type={type}
          inputRef={inputRef}
          className={textInputClasses}
          id={id}
          sx={sx}
          value={value}
          defaultValue={defaultValue}
          placeholder={placeholder}
          onChange={(e: ChangeEvent<HTMLInputElement>) => { onChange?.(e.target.value, e); }}
          tabIndex={-1}
          InputProps={{
            startAdornment: icon,
            endAdornment: endIcon,
          }}
          inputProps={inputProps}
          disabled={disabled}
          onBlur={onBlur}
          onClick={onClick}
          onKeyDown={onKeyDown}
          onFocus={onFocus}
        />
      )}
    </Wrapper>
  );
}
