import React, {
  ReactElement,
} from 'react';
import {
  Control,
  Controller,
  ControllerProps,
  ControllerRenderProps,
} from 'react-hook-form';
import {
  FieldPath,
  FieldValues,
  UseFormStateReturn,
  ControllerFieldState,
} from 'react-hook-form/dist/types';

type RenderProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues
  > = FieldPath<TFieldValues>> = {
  field: Omit<ControllerRenderProps<TFieldValues, TName>, 'ref'>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<TFieldValues>;
};

type Props = Omit<ControllerProps, 'render' | 'control'> & {
  render: (props: RenderProps) => ReactElement;
  control?: Control<any>;
};
export default function FormController({
  render,
  control,
  ...props
}: Props) {
  return (
    <Controller
      {...props}
      control={control}
      render={({ field: { ref, ...field }, ...remainder }) => render({ field, ...remainder })}
    />
  );
}
