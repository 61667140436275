import React, {
  FocusEvent,
  ReactNode,
  useMemo,
  RefObject,
} from 'react';
import clsx from 'clsx';
import isNil from 'lodash/isNil';
import MuiSelect from '@mui/material/Select';
import {
  SelectClasses as MuiSelectClasses,
} from '@mui/material/Select/selectClasses';
import MenuItem from '@mui/material/MenuItem';
import {
  SelectChangeEvent,
} from '@mui/material/Select/SelectInput';
import Label from 'pages/Dashboard/components/Label';
import {
  ChevronDownIcon,
} from 'core/icons';

type GetValue<T> = (option: T, index?: number) => string | number;
type GetLabel<T> = (option: T, index?: number) => string | ReactNode;
type GetDisableStatus<T>= (option: T, index?: number) => boolean;

export type Props<T> = {
  id?: string;
  className?: string;
  labelClasses?: string;
  selectClassName?: string;
  name?: string;
  label?: string;
  required?: boolean;
  multiple?: boolean;
  placeholder?: string;
  defaultValue?: string;
  value?: T | T[] | null;
  options: T[];
  getValue: GetValue<T>;
  getLabel: GetLabel<T>;
  getDisableStatus?: GetDisableStatus<T>;
  onChange: (option: T | T[]) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  renderValue?: (value: string) => ReactNode;
  disabled?: boolean;
  muiClasses?: Partial<MuiSelectClasses>;
  error?: boolean;
  showErrorText?: boolean;
  allowInvalid?: boolean;
  inputRef?: RefObject<any>;
  children?: ReactNode | ((
      options: T[],
      getValue: GetValue<T>,
      getLabel: GetLabel<T>,
      getDisableStatus?: GetDisableStatus<T>,
    ) => ReactNode);};

export default function Select<T>({
  id = '',
  className,
  name,
  labelClasses,
  selectClassName,
  label,
  required,
  placeholder,
  defaultValue,
  value,
  options = [],
  getValue,
  getLabel,
  onChange,
  onBlur,
  multiple,
  renderValue,
  disabled,
  muiClasses,
  error,
  inputRef,
  allowInvalid = false,
  showErrorText = true,
  getDisableStatus,
  children,
}: Props<T>) {
  const classes = clsx(
    'flex flex-col space-y-0.5 relative',
    className,
    { 'has-error': error },
  );
  const selectClasses = clsx(
    'populate-select !rounded',
    selectClassName,
    { disabled },
  );
  const optionsMap = useMemo(() => options.reduce(
    (map: Record<string, T>, item: T) => ({
      ...map,
      [getValue(item)]: item,
    }),
    {},
  ), [options]);

  // Have to do because of broken typings of Material Select component
  // It does not have typings in case of multiple selection
  const singleSelectValue = useMemo(() => {
    const _placeholder: string = placeholder || '';
    if (isNil(value)) return _placeholder;

    const _value: string | number = getValue(value as T);
    const isValidValue = allowInvalid || !isNil(optionsMap[_value]);
    return isValidValue ? _value : _placeholder;
  }, [value, placeholder, optionsMap, allowInvalid]);

  const multiSelectValue = useMemo(() => {
    const _values = ((multiple ? value : []) as T[])
      .map((value: T) => getValue(value))
      .filter((value: string | number) => allowInvalid || optionsMap[value]);
    return placeholder && !_values.length ? [placeholder] : _values;
  }, [value, placeholder, optionsMap, allowInvalid]);

  const _value = useMemo(() => (
    multiple ? multiSelectValue : singleSelectValue
  ), [multiple, multiSelectValue, singleSelectValue]);

  const handleChange = (event: SelectChangeEvent) => {
    const { target: { value } } = event;
    if (Array.isArray(value)) {
      const values: T[] = value
        .filter((_value: string) => _value !== placeholder)
        .map((_value: string) => optionsMap[_value] as T)
        .filter((_value: T) => !!_value);
      return onChange(values);
    }
    const _value: T = optionsMap[value];
    if (!isNil(_value)) {
      onChange(_value);
    }
    return null;
  };

  return (
    <div className={classes}>
      {label && (
        <Label
          className={labelClasses}
          id={id}
          label={label}
          required={required}
        />
      )}
      {error && showErrorText && (
        <span className="absolute right-0 !text-xs text-red-500 font-semibold -top-1">
          Invalid entry
        </span>
      )}
      <MuiSelect
        className={selectClasses}
        id={id}
        name={name}
        defaultValue={defaultValue}
        value={_value as string}
        label={label}
        IconComponent={ChevronDownIcon}
        classes={{
          ...muiClasses,
          select: clsx(muiClasses?.select, { '!pr-3': disabled }),
          icon: clsx(muiClasses?.icon, { '!hidden': disabled }),
        }}
        multiple={multiple}
        onChange={handleChange}
        renderValue={renderValue}
        inputRef={inputRef}
        disabled={disabled}
        onBlur={onBlur}
      >
        {placeholder && (
          <MenuItem
            className="!hidden"
            value={placeholder}
            disabled
          >
            {placeholder}
          </MenuItem>
        )}
        {typeof children === 'function'
          ? children(options, getValue, getLabel, getDisableStatus)
          : options.map((option, index) => (
            <MenuItem
              key={getValue(option, index)}
              value={getValue(option, index)}
              disabled={getDisableStatus?.(option, index) ?? false}
            >
              {getLabel(option, index)}
            </MenuItem>
          ))}
      </MuiSelect>
    </div>
  );
}
