import {
  createTheme,
} from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      light: '#262626',
      main: '#151515',
      dark: '#050505',
    },
    secondary: {
      light: '#3D98FC',
      main: '#1E84F1',
      dark: '#0A74EE',
    },
  },
  typography: {
    fontFamily: 'Montserrat',
  },
});

export { theme };
