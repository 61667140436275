import React from 'react';
import clsx from 'clsx';

export type Prop = {
  id: string;
  className?: string;
  label?: string;
  required?: boolean;
};

export default function Label({
  id,
  className,
  label,
  required,
}: Prop) {
  const classes = clsx('font-semibold relative', className);

  return (
    <label
      htmlFor={id}
      className={classes}
    >
      {label}
      {required && <sup className="text-red-500 !absolute !text-lg -top-2">*</sup>}
    </label>
  );
}
