import React, {
  createContext,
  useCallback,
  useMemo,
  useState,
  ReactNode,
  PropsWithChildren,
} from 'react';
import omit from 'lodash/omit';
import Dialog from 'pages/Dashboard/components/Dialog';

type DialogProps = {
  title?: string;
  className?: string;
  cancelText?: string;
  confirmText?: string;
  onConfirm?: () => void;
  onClose?: () => void;
  disableConfirmButton?: boolean;
  disableBackdropClick?: boolean;
  content: ReactNode;
  showActions?: boolean;
  hideCloseBtn?: boolean;
  dialogClassName?: string;
  closeBtnClasses?: string;
  titleClassName?: string;
};

type ContextValues = {
  openDialog: (props: DialogProps) => void;
  closeDialog: () => void;
};

export const ConfirmationDialogContext = createContext<ContextValues>({
  openDialog: () => {},
  closeDialog: () => {},
});

export default function ConfirmationDialogProvider({ children }: PropsWithChildren) {
  const DEFAULT_PROPS = {
    title: '',
    onConfirm: () => {},
    content: null,
    showActions: true,
  };
  const [props, setProps] = useState<DialogProps>(DEFAULT_PROPS);
  const [open, setOpen] = useState<boolean>(false);

  /// @TODO: Temporary Solution to prevent double click
  const [confirming, setConfirming] = useState<boolean>(false);

  const openDialog = (_props: DialogProps) => {
    setProps({
      ...DEFAULT_PROPS,
      ..._props,
    });
    setConfirming(false);
    setOpen(true);
  };

  const onConfirm = () => {
    setConfirming(true);
    props?.onConfirm?.();
  };

  const closeDialog = useCallback(() => { setOpen(false); }, []);

  const value = useMemo(() => ({
    openDialog,
    closeDialog,
  }), [openDialog, closeDialog]);

  return (
    <ConfirmationDialogContext.Provider value={value}>
      <Dialog
        {...omit(props, ['content'])}
        disableConfirmButton={confirming || props.disableConfirmButton}
        onConfirm={onConfirm}
        onClose={props.onClose ?? closeDialog}
        open={open}
      >
        {props.content}
      </Dialog>
      {children}
    </ConfirmationDialogContext.Provider>
  );
}
