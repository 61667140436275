import React, {
  createContext,
  useState,
  useMemo,
  useEffect,
  ComponentType,
  PropsWithChildren,
} from 'react';
import {
  useLocation,
} from 'react-router-dom';
import Drawer, {
  DrawerClasses,
} from '@mui/material/Drawer';
import isNil from 'lodash/isNil';
import clsx from 'clsx';

export type Entity = Record<string, any> & {
  id: number | string;
};

export type SidePanelComponentProps = {
  entity: Entity;
  onClose?: () => void;
};

type SidePanelProps = {
  entity: Entity;
  Component: ComponentType<SidePanelComponentProps> | null;
  classes?: Partial<DrawerClasses>;
  panelId?: string;
};

type ContextValues = {
  openSidePanel: (props: SidePanelProps) => void;
  closeSidePanel: () => void;
};

export const SidePanelContext = createContext<ContextValues>({
  openSidePanel: () => {},
  closeSidePanel: () => {},
});

export default function DetailedViewContextProvider({ children }: PropsWithChildren) {
  const [props, setProps] = useState<SidePanelProps>({
    entity: { id: 0 },
    Component: null,
  });
  const [open, setOpen] = useState<boolean>(false);
  const location = useLocation();
  const openSidePanel = (_props: SidePanelProps) => {
    setProps(_props);
    setOpen(true);
  };

  const closeSidePanel = () => { setOpen(false); };

  const values = useMemo(() => ({
    openSidePanel,
    closeSidePanel,
  }), [openSidePanel, closeSidePanel]);

  useEffect(() => {
    if (!isNil(props.Component)) {
      closeSidePanel();
      setProps({ entity: { id: 0 }, Component: null });
    }
  }, [location.pathname]);

  const { Component, entity, classes } = props;

  return (
    <SidePanelContext.Provider value={values}>
      <Drawer
        anchor="right"
        open={open}
        onClose={closeSidePanel}
        hideBackdrop
        style={{ height: 'calc(100%-0.875rem)!important' }}
        classes={{
          ...classes,
          root: clsx(classes?.root, 'detailed-entity-drawer'),
        }}
        disableEnforceFocus
        disableScrollLock
        disableAutoFocus
        disableRestoreFocus
      >
        {!isNil(Component) && (
          <Component
            entity={entity}
            onClose={closeSidePanel}
          />
        )}
      </Drawer>
      {children}
    </SidePanelContext.Provider>
  );
}
