import keyBy from 'lodash/keyBy';
import {
  useEncounterSettings,
} from 'pages/Dashboard/pages/Encounters/hooks';

const patientPreAppointmentStatuses = [{
  id: 1,
  caption: 'Would like to be seen sooner',
  value: true,
  isSchedule: true,
}, {
  id: 2,
  caption: 'Don\'t want to be seen sooner',
  value: false,
  isSchedule: true,
}, {
  id: 3,
  caption: 'Has completed Intake',
  value: true,
  isSchedule: false,
}, {
  id: 4,
  caption: 'Has not completed Intake',
  value: false,
  isSchedule: false,
}];

export const preAppointmentStatusMapping = keyBy(patientPreAppointmentStatuses, 'id');

export default function useAppointmentsSettings() {
  const encounterSettings = useEncounterSettings();

  return {
    ...encounterSettings,
    patientPreAppointmentStatus: patientPreAppointmentStatuses,
  };
}
