import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import {
  SvgIconProps,
} from '@mui/material/SvgIcon/SvgIcon';

export default function SearchIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66536 1.33331C3.71985 1.33331 1.33203 3.72113 1.33203 6.66665C1.33203 9.61217 3.71985 12 6.66536 12C7.89784 12 9.03267 11.5819 9.93579 10.8799L13.5273 14.4714C13.7876 14.7317 14.2098 14.7317 14.4701 14.4714C14.7305 14.211 14.7305 13.7889 14.4701 13.5286L10.8786 9.93708C11.5806 9.03395 11.9987 7.89912 11.9987 6.66665C11.9987 3.72113 9.61088 1.33331 6.66536 1.33331ZM2.66536 6.66665C2.66536 4.45751 4.45623 2.66665 6.66536 2.66665C8.8745 2.66665 10.6654 4.45751 10.6654 6.66665C10.6654 8.87579 8.8745 10.6666 6.66536 10.6666C4.45623 10.6666 2.66536 8.87579 2.66536 6.66665Z"
        fill="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>

  );
}
