import compact from 'lodash/compact';
import map from 'lodash/map';
import zipObject from 'lodash/zipObject';
import {
  SettingsDTO,
} from 'dtos';
import {
  useProviders,
} from 'pages/Dashboard/hooks/providers';
import usePrefetchedData from 'pages/Dashboard/hooks/usePrefetchedData';

import {
  EncounterSettings,
} from 'pages/Dashboard/pages/Encounters/types/settings';
import useUserInfo from 'pages/Dashboard/hooks/useUserInfo';
import {
  getApiSettingsGetAppointmentSettings,
} from 'endpoints';
import {
  useGetAppointmentTypes,
} from 'views/EMR/Settings/hooks';
import {
  assignAppointmentTypeColors,
} from 'views/EMR/Settings/helper';

export const colorClasses = Object.freeze([
  '!bg-populate-visit-1',
  '!bg-populate-visit-2',
  '!bg-populate-visit-3',
  '!bg-populate-visit-4',
  '!bg-populate-visit-5',
  '!bg-populate-visit-6',
  '!bg-populate-visit-7',
  '!bg-populate-visit-8',
  '!bg-populate-visit-9',
  '!bg-populate-visit-10',
  '!bg-populate-visit-11',
  '!bg-populate-visit-12',
  '!bg-populate-visit-13',
  '!bg-populate-visit-14',
  '!bg-populate-visit-15',
  '!bg-teal-200',
  '!bg-cyan-200',
  '!bg-emerald-200',
  '!bg-fuchsia-200',
  '!bg-lime-200',
  '!bg-green-200',
  '!bg-indigo-200',
  '!bg-teal-300',
  '!bg-cyan-300',
  '!bg-emerald-300',
  '!bg-fuchsia-300',
  '!bg-lime-300',
  '!bg-green-300',
  '!bg-indigo-300',
  '!bg-teal-400',
  '!bg-cyan-400',
  '!bg-emerald-400',
  '!bg-fuchsia-400',
  '!bg-lime-400',
  '!bg-green-400',
  '!bg-indigo-400',
  '!bg-teal-500',
  '!bg-cyan-500',
  '!bg-emerald-500',
  '!bg-fuchsia-500',
  '!bg-lime-500',
  '!bg-green-500',
  '!bg-indigo-500',
]);

const officeColorsClasses = Object.freeze([
  '!bg-orange-700',
  '!bg-sky-700',
  '!bg-fuchsia-700',
  '!bg-lime-700',
  '!bg-teal-700',
  '!bg-violet-700',
  '!bg-rose-700',
  '!bg-green-700',
  '!bg-amber-700',
  '!bg-emerald-700',
]);

const claimStatusTypeColors = Object.freeze({
  'Not Started': '!bg-stone-200 !text-stone-600 !border-stone-300',
  Draft: '!bg-slate-200 !text-slate-600 !border-slate-300',
  Hold: '!bg-orange-200 !text-orange-900 !border-orange-600',
  Validated: '!bg-teal-100 !text-teal-800 !border-teal-300',
  Accepted: '!bg-blue-100 !text-blue-700 !border-blue-300',
  Submitted: '!bg-lime-100 !text-lime-700 !border-lime-300',
  Paid: '!bg-green-200 !text-green-700 !border-green-300',
  Processed: '!bg-indigo-100 !text-indigo-700 !border-indigo-300',
  Denied: '!bg-red-100 !text-red-700 !border-red-300',
  Rejected: '!bg-orange-100 !text-orange-700 !border-orange-300',
  Voided: '!bg-gray-200 !text-gray-500 !border-gray-300',
});

const miscColors = Object.freeze({
  amountTag: 'border bg-gray-50 font-semibold text-gray-600',
  customService: 'text-black bg-gray-100',
  terminal: 'bg-white text-gray-500 border !border-gray-300 font-semibold',
  task: 'text-gray-700 font-semibold',
  eobPosted: 'bg-sky-100 text-sky-500 !border-sky-300 font-semibold',
  eobNotPosted: 'bg-white text-gray-500 border !border-gray-300 font-semibold',
  fileReady: '!bg-emerald-100 !text-emerald-700 !border-emerald-300',
  filePending: '!bg-stone-200 !text-stone-600 !border-stone-300',
});

export function useEncounterSettings(): EncounterSettings {
  const { data: doctors } = useProviders();
  const { data } = usePrefetchedData<SettingsDTO>({
    key: 'appointment-settings',
    fetchFn: getApiSettingsGetAppointmentSettings,
  });
  const userInfo = useUserInfo();

  const { appointmentTypes, durationByType } = useGetAppointmentTypes();

  const appointmentStatuses = (data?.patientAppointmentStatusSettings ?? [])
    .map((item) => item.patientAppointmentStatus) as string[];
  const encounterTypeStatuses = appointmentStatuses.filter((item) => !/note finalized/i.test(item ?? ''));

  return {
    doctors: (doctors ?? []).map((doctor) => ({
      name: doctor.name,
      userId: doctor.id,
    })),
    appointmentTypes,
    durationByType,
    appointmentStatuses,
    encounterTypeStatuses,
    officeLocation: userInfo?.organization?.officeLocations ?? [],
  };
}

function useAppointmentTypeColors(): Record<string, string> {
  const { appointmentTypes } = useGetAppointmentTypes();
  return assignAppointmentTypeColors(appointmentTypes);
}

export function useOfficeLocationColors(): Record<string, string> {
  const userInfo = useUserInfo();
  const officeLocations = compact(map(userInfo?.organization?.officeLocations ?? [], 'nickName'));
  return zipObject(
    officeLocations,
    officeLocations.map((type, index) => officeColorsClasses[index % officeLocations.length]),
  );
}

const faxColors: Record<string, string> = Object.freeze({
  New: '!bg-blue-100',
  Read: '!bg-blue-300',
  Archived: '!bg-gray-200',
  Sent: '!bg-green-200',
  Failed: '!bg-red-300',
});

export const ledgerColors = Object.freeze({
  netCharges: 'bg-blue-400 text-blue-400 border-blue-400',
  insurancePaid: 'bg-teal-400 text-teal-400 border-teal-400',
  insuranceBalance: 'bg-green-400 text-green-400 border-green-400',
  patientPaid: 'bg-violet-400 text-violet-400 border-violet-400',
  patientBalance: 'bg-pink-400 text-pink-400 border-pink-400',
});

export function useColorsHashMap(): Record<string, string> {
  const appointmentTypeColors = useAppointmentTypeColors();
  const officeColors = useOfficeLocationColors();
  return {
    ...miscColors,
    ...appointmentTypeColors,
    ...officeColors,
    ...claimStatusTypeColors,
    ...faxColors,
    ...ledgerColors,
  };
}
