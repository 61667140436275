import React, {
  FocusEvent,
} from 'react';
import clsx from 'clsx';
import MaskedTextField from 'core/components/MaskedTextField';

const mask = [{
  mask: '00000',
  lazy: true,
  overwrite: 'shift',
},
{
  mask: '00000-0000',
  lazy: true,
  overwrite: 'shift',
}];

type Props = {
  id?: string;
  disabled?: boolean;
  value?: string | null;
  onChange: (value: string | number | null) => void;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  className?: string;
  inputProps?: object;
};

export default function ZipCodeTextField({
  value,
  className,
  onChange,
  onFocus,
  ...props
}: Props) {
  return (
    <MaskedTextField
      {...props}
      mask={mask}
      value={value ?? ''}
      placeholder="Zip Code"
      onAccept={onChange}
      className={clsx('populate-input populate-zipcode-input bg-white', className)}
    />
  );
}
