import {
  FaxContactDTO,
  FaxContactDTOPaginatedResponseDTO,
  FaxViewDTO,
  FaxViewDTOPaginatedResponseDTO,
  PatientInfo,
} from 'dtos';
import isNil from 'lodash/isNil';
import {
  formatDate,
  formatDateTime,
} from 'utils/date';
import {
  formatPhone,
  getFullName,
} from 'utils/string';

export const formatPatientInfo = (patient: PatientInfo) => ({
  ...patient,
  dateOfBirth: patient?.dateOfBirth ?? undefined,
  formattedDateOfBirth: formatDate(patient?.dateOfBirth ?? ''),
  fullName: getFullName(patient),
  firstName: patient.firstName ?? undefined,
});

export const normalizeFaxesResponse = (response: FaxViewDTOPaginatedResponseDTO): FaxViewDTO[] => (
  response?.data?.map((fax: FaxViewDTO) => ({
    ...fax,
    date: formatDateTime(fax?.date ?? ''),
    patient: isNil(fax?.patient)
      ? undefined
      : formatPatientInfo(fax?.patient ?? {}),
  })) ?? []
);

export const normalizeContactsResponse = (
  response: FaxContactDTOPaginatedResponseDTO,
): FaxContactDTO[] => response?.data?.map((contact: FaxContactDTO) => ({
  ...contact,
  faxNumber: formatPhone(contact?.faxNumber ?? ''),
})) ?? [];
