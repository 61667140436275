import isNil from 'lodash/isNil';
import {
  requestInstance,
} from 'services/api';
import dayjs from 'utils/dayjs';
import {
  axiosInstance,
} from 'utils/httpClient';

export type TokenItem = {
  expiring_at: number;
  token: string;
};

export const generateTokenItem = (token: string): TokenItem => ({
  token,
  expiring_at: dayjs().add(1, 'day').valueOf(),
});

export const getIsTokenItemValid = (tokenItem: TokenItem): boolean => (
  !isNil(tokenItem.expiring_at)
  && !dayjs().subtract(5, 'minutes').isAfter(tokenItem.expiring_at)
  && !isNil(tokenItem.token)
);

export const getAccessTokenFromLocalStorage = (): TokenItem => (
  JSON.parse(localStorage.getItem('populate_token') ?? '{ "expiring_at": 0 }')
);

export const setAccessTokenToLocalStorage = (tokenItem: TokenItem) => {
  localStorage.setItem('populate_token', JSON.stringify(tokenItem));
};

export const setHeaders = (token: string) => {
  const bearer = `Bearer ${token}`;
  axiosInstance.defaults.headers.common.Authorization = bearer;
  requestInstance.defaults.headers.common.Authorization = bearer;
};
