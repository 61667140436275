import React, {
  ReactNode,
} from 'react';
import {
  AxiosError,
} from 'axios';
import values from 'lodash/values';
import uniqueId from 'lodash/uniqueId';
import {
  isEmptyString,
} from 'utils/misc';

type CommonErrorDataResponse = {
  errors: Record<string, string[]>;
};

type ErrorResponse = {
  Message: string;
};

type ExternalErrorResponse = {
  message: string;
};

type ErrorItem = Partial<{
  id: string;
  message: string;
}>;

export function getFormErrorList(errors: object) {
  return values(errors).map((error: ErrorItem) => ({ ...error, id: uniqueId('error__') }));
}

export function ErrorList({ errors }: { errors: ErrorItem[] }) {
  return errors.length > 0
    ? (
      <ul className="list-disc list-inside">
        {errors.map((error) => <li key={error.id}>{error?.message ?? ''}</li>)}
      </ul>
    )
    : null;
}

export function getErrorMessage(error: unknown): ReactNode | string | null {
  const plainTextError = typeof error === 'string' ? error : null;
  const axiosError = error as AxiosError;
  const data = axiosError?.response?.data;

  const errorsAsObject = typeof data === 'object'
    ? (
      ((data as ErrorResponse)?.Message ?? (data as ExternalErrorResponse)?.message)
      ?? values((data as CommonErrorDataResponse)?.errors)?.flat()?.map(
        (error) => ({
          message: error,
          id: uniqueId('api_error_'),
        }),
      )
    ) : null;

  const errorMessage = isEmptyString(data) ? null : (data as string);
  const responseMessage = typeof data === 'string' ? data as string : errorMessage;

  return (Array.isArray(errorsAsObject) && errorsAsObject?.length > 0)
    ? <ErrorList errors={errorsAsObject} />
    : errorsAsObject ?? plainTextError ?? responseMessage ?? null;
}
