import React, {
  createContext,
  PropsWithChildren,
  useMemo,
  useEffect,
  useCallback,
  useState,
} from 'react';
import * as signalR from '@microsoft/signalr';
import {
  HubConnection,
  IHttpConnectionOptions,
  LogLevel,
} from '@microsoft/signalr';
import {
  getAccessTokenFromLocalStorage,
} from 'utils/auth';
import {
  baseApiUrl,
} from 'env';
import {
  signalRConfig,
} from 'config/signalRConfig';

type SignalRHubContextProps = {
  hubConnection: HubConnection | null;
};

export const SignalRHubContext = createContext<SignalRHubContextProps>({
  hubConnection: null,
});

const getHubOptions = (): IHttpConnectionOptions => {
  const cachedTokenItem = getAccessTokenFromLocalStorage();

  const options: IHttpConnectionOptions = {
    withCredentials: false, // true when we have allowed credentials in the server
    skipNegotiation: false, // keep it false always
    transport: signalR.HttpTransportType.WebSockets,
    accessTokenFactory(): string {
      return cachedTokenItem?.token ?? '';
    },
  };

  options.headers = {
    ...(options.headers ?? {}),
    Authorization: `Bearer ${cachedTokenItem?.token}`,
  };

  return options;
};

export function getNewHubConnection(): HubConnection {
  const endpoint = `${baseApiUrl}/${signalRConfig.signalRPrefix}/notifications`;

  const options: IHttpConnectionOptions = getHubOptions();
  const loggerType = signalRConfig.isLogEnabled
    ? LogLevel.Information
    : LogLevel.Error;

  return new signalR
    .HubConnectionBuilder()
    .withUrl(endpoint, options)
    .withAutomaticReconnect(signalRConfig.retry)
    .configureLogging(loggerType)
    .build();
}

export default function SignalRHubProvider({
  children,
}: PropsWithChildren) {
  const [hubConnection, setHubConnection] = useState<HubConnection | null>(null);

  const createConnection = async () => {
    try {
      const connection = getNewHubConnection();
      await connection.start();
      setHubConnection(connection);
    } catch (error) {
      console.error('Error creating connection', error);
    }
  };

  const resetHubConnection = useCallback(async () => {
    await hubConnection?.stop();
    setHubConnection(null);
  }, [hubConnection]);

  useEffect(() => {
    createConnection();

    return () => {
      resetHubConnection();
    };
  }, []);

  const value = useMemo(() => ({
    hubConnection,
  }), [hubConnection]);

  return (
    <SignalRHubContext.Provider value={value}>
      {children}
    </SignalRHubContext.Provider>
  );
}
