import {
  request,
} from 'services/api';
import isNil from 'lodash/isNil';
import {
  normalizeAppointmentWithPayments,
  normalizeAvailability,
  normalizeEvents,
  normalizeTimeBlock,
  serializeAppointmentForUpdate,
  serializeProviderAvailability,
  serializeTimeBlock,
} from 'pages/Dashboard/utils/helper';
import {
  CreatePatientPayload,
} from 'pages/Dashboard/pages/Appointments/pages/List/types/patient';
import {
  CalendarEvent,
  CalendarEventWithPartialOptions,
  CalendarTimelineEvent,
} from 'pages/Dashboard/pages/Appointments/pages/List/types/appointment';
import {
  AppointmentDTO,
  AppointmentDetailsDTO,
  BlockedTimeSlotDTO,
  CalendarEventsResponseDTO,
  DeleteAppointmentRequestDTO,
  DeleteCalendarSlotResponseDTO,
  DoctorsAppointmentSummaryDTO,
  PatientDTO,
  ProblemDetails,
  ProviderAvailabilityDTO,
  ReminderCommuniqueRequestDTO,
} from 'dtos';
import {
  normalizeDailyBreakdown,
} from 'pages/Dashboard/pages/Appointments/pages/List/utils/helper';
import {
  AppointmentForm,
} from 'pages/Dashboard/pages/Appointments/pages/List/components/Details';

export const savePatient = (data: Partial<CreatePatientPayload>): Promise<PatientDTO> => (
  request<PatientDTO>('Patient/CreateNewPatient', {
    method: 'POST',
    data,
  })
);

export const getCalendarEventsByDateRange = (
  startDate: string,
  endDate: string,
):
  Promise<CalendarEvent[]> => (
  request<CalendarEventsResponseDTO>(
    'Encounter/GetCalendarEventsByDateRange',
    {
      method: 'GET',
      params: {
        startDate: encodeURI(startDate),
        endDate: encodeURI(endDate),
      },
    },
  ).then(normalizeEvents)
);

export const getAppointmentsDailyBreakdown = (
  startDate: string,
  endDate: string,
):
  Promise<CalendarTimelineEvent[]> => (
  request<DoctorsAppointmentSummaryDTO[]>(
    'Encounter/GetDailyAppointmentBreakdownByDateRange',
    {
      method: 'GET',
      params: {
        startDate: encodeURI(startDate),
        endDate: encodeURI(endDate),
      },
    },
  ).then(normalizeDailyBreakdown)
);

export const getAppointmentById = (id: number): Promise<CalendarEvent> => (
  request<AppointmentDetailsDTO>(`Encounter/GetAppointmentDetailsByAppointmentId/${id}`, { method: 'GET' })
    .then(normalizeAppointmentWithPayments)
);

export const getBlockedTimeSlotById = (id: number): Promise<CalendarEvent> => (
  request<BlockedTimeSlotDTO>('BlockedTimeSlot/GetBlockedTimeSlot', {
    method: 'GET',
    params: {
      blockedTimeSlotId: id,
    },
  })
    .then(normalizeTimeBlock)
);

export const getProviderAvailabilitySlotById = (providerAvailabilityId: number):
Promise<CalendarEvent> => (request<ProviderAvailabilityDTO>('ProviderAvailability/GetProviderAvailability', {
  method: 'GET',
  params: {
    providerAvailabilityId,
  },
})
  .then(normalizeAvailability)
);

export const deleteAppointment = ({ data }: CalendarEventWithPartialOptions):
  Promise<AppointmentDTO> => request<DeleteAppointmentRequestDTO>('Encounter/DeleteAppointment', {
    method: 'DELETE',
    data: { encounterId: data.objectId },
  });

export const sendAppointmentReminder = (data: ReminderCommuniqueRequestDTO):
  Promise<void> => request<void>('Communication/SendReminderCommunique', {
    method: 'POST',
    data,
  });

export const modifyAppointment = (data: Partial<CalendarEvent>):
  Promise<AppointmentDTO> => request<AppointmentDTO>('Encounter/ModifyAppointmentDetails', {
    method: 'PATCH',
    data: serializeAppointmentForUpdate(data),
  });

export const modifyBlockedTimeSlot = (data: Partial<CalendarEvent>):
  Promise<BlockedTimeSlotDTO> => request<BlockedTimeSlotDTO>('BlockedTimeSlot/AddOrUpdate', {
    method: 'POST',
    data: serializeTimeBlock(data),
  });

export const deleteBlockedTimeSlotAll = (data: CalendarEvent):
  Promise<ProblemDetails> => request<ProblemDetails>('BlockedTimeSlot/Delete', {
    method: 'DELETE',
    params: { blockedTimeSlotId: data.objectId },
  });

export const deleteBlockedTimeSlotPartially = ({ data, options }: CalendarEventWithPartialOptions):
Promise<DeleteCalendarSlotResponseDTO> => request<DeleteCalendarSlotResponseDTO>('BlockedTimeSlot/DeletePartially', {
  method: 'POST',
  data: {
    blockedTimeSlotId: data.objectId,
    ...(options ?? {}),
  },
});

export const deleteBlockedTimeSlot = ({ data, options }: CalendarEventWithPartialOptions):
  Promise<ProblemDetails | DeleteCalendarSlotResponseDTO> => (
  !isNil(options?.deleteSingleItem)
    ? deleteBlockedTimeSlotPartially({ data, options })
    : deleteBlockedTimeSlotAll(data)
);

export const modifyAvailability = (data: Partial<CalendarEvent> & AppointmentForm):
Promise<ProviderAvailabilityDTO> => request<ProviderAvailabilityDTO>('ProviderAvailability/AddOrUpdate', {
  method: 'POST',
  data: serializeProviderAvailability(data),
});

const deleteAllProviderAvailability = ({ objectId: providerAvailabilityId }: CalendarEvent):
  Promise<ProblemDetails> => request<ProblemDetails>(`ProviderAvailability/Delete?providerAvailabilityId=${providerAvailabilityId}`, {
    method: 'DELETE',
  });

const deletePartialRecurringProviderAvailability = ({
  data,
  options,
}: CalendarEventWithPartialOptions):
  Promise<DeleteCalendarSlotResponseDTO> => request<DeleteCalendarSlotResponseDTO>('ProviderAvailability/DeletePartially', {
    method: 'POST',
    data: {
      providerAvailabilityId: data?.objectId,
      ...(options ?? {}),
    },
  });

export const deleteAvailability = ({ data, options }: CalendarEventWithPartialOptions):
  Promise<ProblemDetails | DeleteCalendarSlotResponseDTO> => (
  !isNil(options?.deleteSingleItem)
    ? deletePartialRecurringProviderAvailability({ data, options })
    : deleteAllProviderAvailability(data)
);
