import {
  PaginationState,
} from '@tanstack/react-table';
import {
  DateTimeOffsetRangeDTO,
  EFaxFilterDTO,
  FaxContactDTO,
  FaxViewDTO,
  FaxViewDTOPaginatedResponseDTO,
  MultiMediaResponseDTO,
  PagePropertiesRequestDTO,
} from 'dtos';
import {
  formatISODateTime,
} from 'utils/date';
import {
  sendRequest,
} from 'utils/httpClient';
import {
  normalizeContactsResponse,
  normalizeFaxesResponse,
} from 'pages/Dashboard/pages/Fax/util';
import {
  getApiEFaxGetFaxContacts,

  postApiEFaxGetFaxes,
} from 'endpoints';

export type UploadToPatientChartParams = {
  patientId: number;
  inboundFaxId: number;
  mediaCategory: string;
};

export type ResendFaxParams = {
  outboundFaxId: number;
};

export const uploadInboundFileToPatientChart = (
  data: UploadToPatientChartParams,
): Promise<FaxViewDTO> => (
  sendRequest<FaxViewDTO>({ url: '/api/eFax/uploadInboundFileToPatientChart' }, {
    method: 'POST',
    data,
  })
);

export const getMultiMediaByFaxId = (
  faxId: number,
): Promise<MultiMediaResponseDTO> => (
  sendRequest<MultiMediaResponseDTO>({
    url: '/api/Multimedia/getFaxMultimediaByFaxId',
  }, {
    method: 'GET',
    params: {
      faxId,
    },
  })
);

export type GetFaxesParams = {
  dateRange: DateTimeOffsetRangeDTO;
  pageRequest: PagePropertiesRequestDTO;
};

export const getFaxes = (
  data: GetFaxesParams,
): Promise<FaxViewDTOPaginatedResponseDTO> => (
  sendRequest<FaxViewDTOPaginatedResponseDTO>({
    url: '/api/eFax/getFaxes',
  }, {
    method: 'POST',
    data,
  })
);

export type ContactsDataParams = {
  dateRange?: Date[];
  pagination?: PaginationState;
  searchQuery?: string;
  setTotalSize?: (size: number) => void;
};

export type FaxesDataParams = {
  dateRange?: Date[];
  pagination?: PaginationState;
  setTotalSize?: (size: number) => void;
  searchQuery?: string;
  filterQuery?: EFaxFilterDTO;
};

export const getContactsData = async ({
  pagination,
  searchQuery,
  setTotalSize,
}: ContactsDataParams): Promise<any[]> => {
  const response = await getApiEFaxGetFaxContacts({
    searchText: searchQuery,
    Page: pagination?.pageIndex ?? 0,
    PageSize: pagination?.pageSize ?? 25,
  });

  setTotalSize?.(response.pageProperties?.totalSize ?? 0);
  const normalized = normalizeContactsResponse(response);

  return normalized;
};

export const getFaxesData = async ({
  dateRange,
  pagination,
  setTotalSize,
  filterQuery,
  searchQuery,
}: FaxesDataParams): Promise<FaxViewDTO[]> => {
  const [start, end] = (dateRange ?? []).map((date) => formatISODateTime(date));
  const response = await postApiEFaxGetFaxes({
    dateRange: { start, end },
    pageRequest: {
      page: pagination?.pageIndex ?? 0,
      pageSize: pagination?.pageSize ?? 25,
    },
    filterQuery,
    searchText: searchQuery,
  });

  setTotalSize?.(response.pageProperties?.totalSize ?? 0);
  const normalized = normalizeFaxesResponse(response);

  return normalized;
};

export const resendFax = (data: ResendFaxParams): Promise<FaxViewDTO> => (
  sendRequest<FaxViewDTO>(
    { url: `/api/eFax/resendFax?outboundFaxId=${data.outboundFaxId}` },
    { method: 'POST' },
  )
);

export const sendFax = (data: Partial<FaxViewDTO>): Promise<FaxViewDTO> => (
  sendRequest<FaxViewDTO>({ url: '/api/eFax/sendFax' }, { method: 'POST', data })
);

export const getContacts = (): Promise<FaxContactDTO[]> => (
  sendRequest<FaxContactDTO[]>({
    url: '/api/eFax/getFaxContacts',
  }, {
    method: 'GET',
  })
);

export const addContact = (data: Partial<FaxContactDTO>): Promise<FaxContactDTO> => (
  sendRequest<FaxContactDTO>({
    url: '/api/eFax/addFaxContact',
  }, {
    method: 'POST',
    data,
  })
);
