import {
  FaxContactDTO,
  FaxContactDTOPaginatedResponseDTO,
  FaxViewDTO,
  GetApiEFaxGetFaxContactsParams,
  MultiMediaResponseDTO,
  PostApiEFaxSetInboundFaxInternalStatusParams,
  SettingsDTO,
} from 'dtos';
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
} from 'react-query';
import {
  getMultiMediaByFaxId,
  resendFax,
  sendFax,
  uploadInboundFileToPatientChart,
} from 'pages/Dashboard/pages/Fax/transport';
import {
  PostApiEFaxSetInboundFaxInternalStatusResult,
  deleteApiEFaxDeleteFaxContact,
  getApiSettingsGetEFaxFilterSettings,
  getApiToDoGetFaxToDos,
  getApiEFaxGetFaxContacts,
  postApiEFaxSetInboundFaxInternalStatus,
  postApiEFaxUpdateFaxContact,
  postApiEFaxAddFaxContact,
} from 'endpoints';
import usePrefetchedData from 'pages/Dashboard/hooks/usePrefetchedData';
import {
  normalizeFilterableSettings,
} from 'core/Filters/util';
import {
  ResendFaxParams,
  UploadToPatientChartParams,
} from 'pages/Dashboard/pages/Fax/types';
import {
  ToDo,
} from 'types/toDo';
import {
  normalizeToDos,
} from 'pages/Dashboard/pages/Todos/normalize';

export function useUploadInboundFileToPatientChart():
  UseMutationResult<FaxViewDTO, Error, UploadToPatientChartParams> {
  return (
    useMutation<FaxViewDTO, Error, UploadToPatientChartParams>(uploadInboundFileToPatientChart)
  );
}

export function useGetMediaByFaxId(
  faxId: number,
  enabled: boolean,
): UseQueryResult<MultiMediaResponseDTO, Error> {
  return useQuery<MultiMediaResponseDTO, Error>(
    [faxId],
    () => getMultiMediaByFaxId(faxId),
    { enabled },
  );
}

export function useGetFaxToDos(
  faxId: number,
): UseQueryResult<ToDo[], Error> {
  return useQuery<ToDo[], Error>(
    ['faxToDos', faxId],
    async () => {
      const response = await getApiToDoGetFaxToDos({ faxId });
      return normalizeToDos(response);
    },
  );
}

export function useResendFax(): UseMutationResult<FaxViewDTO, Error, ResendFaxParams> {
  return useMutation<FaxViewDTO, Error, ResendFaxParams>(resendFax);
}

export function useSendFax(): UseMutationResult<FaxViewDTO, Error, Partial<FaxViewDTO>> {
  return useMutation<FaxViewDTO, Error, Partial<FaxViewDTO>>(sendFax);
}

export function useContacts(
  searchText: string,
  page: number,
  pageSize: number,
  enabled: boolean,
): UseQueryResult<FaxContactDTOPaginatedResponseDTO, Error> {
  return useQuery<FaxContactDTOPaginatedResponseDTO, Error>(
    ['contacts', searchText, page, pageSize],
    () => getApiEFaxGetFaxContacts({
      searchText: searchText?.trim() ?? '',
      page,
      pageSize,
    } as GetApiEFaxGetFaxContactsParams),
    { enabled, keepPreviousData: true },
  );
}

export function useUpdateFaxStatusHandler():
  UseMutationResult<
  PostApiEFaxSetInboundFaxInternalStatusResult,
  Error,
  PostApiEFaxSetInboundFaxInternalStatusParams
  > {
  return useMutation<
  PostApiEFaxSetInboundFaxInternalStatusResult,
  Error,
  PostApiEFaxSetInboundFaxInternalStatusParams>(postApiEFaxSetInboundFaxInternalStatus);
}

export function useAddContact():
  UseMutationResult<FaxContactDTO, Error, Partial<FaxContactDTO>> {
  return useMutation<FaxContactDTO, Error, Partial<FaxContactDTO>>(postApiEFaxAddFaxContact);
}

export function useUpdateContact():
  UseMutationResult<FaxContactDTO, Error, Partial<FaxContactDTO>> {
  return useMutation<FaxContactDTO, Error, Partial<FaxContactDTO>>(postApiEFaxUpdateFaxContact);
}

export function useDeleteContact():
  UseMutationResult<FaxContactDTO, Error, { faxContactId: number }> {
  return useMutation<FaxContactDTO, Error, { faxContactId: number }>(deleteApiEFaxDeleteFaxContact);
}

export function useGetEFaxFilterMetaComponents() {
  const { data } = usePrefetchedData<SettingsDTO>({
    key: 'eFax-settings',
    fetchFn: getApiSettingsGetEFaxFilterSettings,
  });
  return normalizeFilterableSettings(data?.filterableSettings ?? []);
}
