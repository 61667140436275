import React, {
  lazy,
} from 'react';
import {
  createBrowserRouter,
  Navigate,
  Outlet,
} from 'react-router-dom';
import ProtectedRouter from 'pages/Auth/ProtectedRouter';
import AuthorizeScreen from 'pages/Auth/authorize';
import legacyRoutes from 'pages/Dashboard/routes';
import routes from 'views/EMR/routes';
import ErrorBoundary from 'pages/Dashboard/components/ErrorBoundary';
import LazyLoadComponent from 'core/components/LazyLoadComponent';

function ErrorBoundaryLayout() {
  return (
    <ErrorBoundary>
      <Outlet />
    </ErrorBoundary>
  );
}

export default createBrowserRouter([{
  element: <ErrorBoundaryLayout />,
  children: [{
    path: '/',
    element: (
      <ProtectedRouter>
        <LazyLoadComponent
          Component={lazy(() => import('views/EMR/Layout'))}
        />
      </ProtectedRouter>
    ),
    children: [
      ...legacyRoutes.map((route) => ({
        path: `/${route?.href}`,
        element: (
          <LazyLoadComponent
            Component={lazy(() => import(`pages/Dashboard/pages/${route?.name}`))}
          />
        ),
        ...(route?.subRoutes ? { children: route.subRoutes } : {}),
      })),
      ...routes.map((route) => ({
        path: `/${route?.href}`,
        element: (
          <LazyLoadComponent
            Component={lazy(() => import(`views/EMR/${route?.name}`))}
          />
        ),
        ...(route?.subRoutes ? { children: route.subRoutes } : {}),
      })), {
        path: '/',
        element: <Navigate to="/encounters" />,
      }, {
        path: '*',
        element: <Navigate to="/encounters" />,
      },
    ],
  }, {
    path: '/authorize',
    element: <AuthorizeScreen />,
  }, {
    path: '*',
    element: <Navigate to="/" />,
  }],
}]);
