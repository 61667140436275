export const environment = process.env.REACT_APP_ENV as string;
export const subscriptionKey = process.env.REACT_APP_AZURE_SUBSCRIPTION_KEY as string;
export const region = process.env.REACT_APP_AZURE_REGION as string;
export const apiUrl = process.env.REACT_APP_API_URL as string;
export const baseApiUrl = process.env.REACT_APP_API_BASE_URL as string;

export const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN as string;
export const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID as string;
export const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE as string;
export const sentryDSN = process.env.REACT_APP_SENTRY_DSN;

export const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;
