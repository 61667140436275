import React, {
  FocusEvent,
} from 'react';
import clsx from 'clsx';
import MaskedTextField from 'core/components/MaskedTextField';
import UnitedStateFlag from 'core/icons/unitedStateFlag';
import InputAdornment from '@mui/material/InputAdornment';

const mask = [{
  mask: '{+1} (000) 000-0000',
  lazy: false,
  overwrite: true,
}];

type Props = {
  value?: string | null;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  readonly?: boolean;
  error?: boolean;
  name: string;
  id: string;
  className?: string;
  inputClassName?: string;
  onChange: (val: string) => void;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
};

export default function PhoneInput({
  value,
  placeholder,
  disabled,
  required,
  onChange,
  className,
  inputClassName,
  readonly: readOnly,
  error,
  ...props
}: Props) {
  const classes = clsx('flex items-center w-full overflow-hidden rounded bg-gray-100');

  return (
    <div className={classes}>
      <MaskedTextField
        {...props}
        disabled={disabled}
        mask={mask}
        value={value ?? ''}
        onAccept={onChange}
        className={clsx('populate-phone-input bg-white', className)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" className="-ml-1.5">
              <UnitedStateFlag className="!text-base" />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}
