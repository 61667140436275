import {
  useQuery,
  UseQueryResult,
} from 'react-query';
import {
  getApiEncounterGetEncounterSummariesByDate,
  getApiEncounterGetEncounterDetailsById,
} from 'endpoints';
import {
  EncounterViewModel,
  EncounterDetailsViewModel,
} from 'dtos';

export function useEncounters(date: string): UseQueryResult<EncounterViewModel[], Error> {
  return useQuery<EncounterViewModel[], Error>(
    ['encounters', date],
    () => getApiEncounterGetEncounterSummariesByDate({ date }),
  );
}

export function useEncounterDetails(encounterId: number):
UseQueryResult<EncounterDetailsViewModel, Error> {
  return useQuery<EncounterDetailsViewModel, Error>(
    ['encounter-details', encounterId],
    () => getApiEncounterGetEncounterDetailsById({ encounterId }),
  );
}
