import React, {
  lazy,
} from 'react';
import {
  Navigate,
  RouteObject,
} from 'react-router-dom';
import LazyLoadComponent from 'core/components/LazyLoadComponent';

export const billingRoutes: RouteObject[] = [{
  path: '/billing/claims',
  element: (
    <LazyLoadComponent Component={lazy(() => import('./Charges'))} />
  ),
}, {
  path: '/billing/charge-master/:type',
  element: (
    <LazyLoadComponent Component={lazy(() => import('./ChargeMaster'))} />
  ),
}, {
  path: '/billing/payments/:type',
  element: (
    <LazyLoadComponent Component={lazy(() => import('./Payments'))} />
  ),
}, {
  path: '/billing/statements',
  element: (
    <LazyLoadComponent Component={lazy(() => import('./Statements'))} />
  ),
}, {
  path: '/billing/claims/:id',
  element: (
    <LazyLoadComponent Component={lazy(() => import('./ClaimForm'))} />
  ),
}, {
  path: '/billing',
  element: <Navigate to="/billing/claims" replace />,
}, {
  path: '/billing/charges',
  element: <Navigate to="/billing/claims" replace />,
}, {
  path: '/billing/payments',
  element: <Navigate to="/billing/payments/eobs" replace />,
}, {
  path: '/billing/eobs',
  element: <Navigate to="/billing/payments/eobs" replace />,
}, {
  path: '/billing/charge-master',
  element: <Navigate to="/billing/charge-master/cpt" replace />,
}];
