import useUrlSyncState from 'pages/Dashboard/hooks/useUrlSyncState';
import {
  formatDateOnly,
  getDateRangeFromStringParam,
} from 'utils/date';
import dayjs from 'utils/dayjs';
import {
  DateUnit,
} from 'utils/misc';

export type DateRangeParams = {
  unit?: DateUnit;
  amount?: number;
  uniqueKey?: string;
};

export function useSyncDateRange({
  unit = 'month',
  amount = 3,
  uniqueKey,
}: DateRangeParams) {
  const now = dayjs();
  const defaultFormattedEnd = formatDateOnly(now);
  const defaultFormattedStart = formatDateOnly(now.subtract(amount, unit));
  const defaultDateRange = getDateRangeFromStringParam(`${defaultFormattedStart}--${defaultFormattedEnd}`);

  return useUrlSyncState<Date[]>(
    defaultDateRange,
    'dates',
    // convertStateToQueryParam
    (value) => {
      const [from, to] = value;
      return `${formatDateOnly(from)}--${formatDateOnly(to)}`;
    },
    // convertQueryParamToState
    (param) => (
      param === ''
        ? defaultDateRange
        : getDateRangeFromStringParam(param)
    ),
    uniqueKey,
  );
}
