import axios, {
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import {
  apiUrl,
} from 'env';

export const requestInstance = axios.create({ baseURL: apiUrl });

/**
 * @deprecated
 * use sendRequest from util/httpClient instead
 */
export const request = async <T>(
  url: string,
  config: AxiosRequestConfig,
): Promise<T> => requestInstance.request<T>({
  url,
  ...config,
}).then((res: AxiosResponse) => res.data);
