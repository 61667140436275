import React, {
  FC,
  ReactNode,
} from 'react';
import isNil from 'lodash/isNil';
import {
  SvgIconProps,
} from '@mui/material/SvgIcon';
import {
  SxProps,
  Theme,
} from '@mui/material/styles';
import MuiBadge from '@mui/material/Badge';

const defaultBadgeStyle = {
  '.MuiBadge-badge': {
    fontSize: '0.625rem',
    minWidth: '0.875rem !important',
    height: '0.875rem !important',
    paddingLeft: '.25rem',
    paddingRight: '.25rem',
  },
};

type Parts = 'icon' | 'badge' ;

export type BadgeProps = {
  badgeContent?: ReactNode;
  Icon?: FC<SvgIconProps>;
  invisible?: boolean;
  color?: 'primary' | 'error' | 'secondary' | 'info';
  children?: ReactNode;
  sx?: SxProps<Theme>;
  classes?: Partial<Record<Parts, string>>;
};

export default function Badge({
  Icon,
  classes,
  children,
  sx = defaultBadgeStyle,
  ...props
}: BadgeProps) {
  return (
    <MuiBadge
      {...props}
      sx={sx}
      max={99}
      className={classes?.badge}
    >
      {!isNil(Icon) && <Icon className={classes?.icon} />}
      {children}
    </MuiBadge>
  );
}
