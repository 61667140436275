import React from 'react';
import Tag from 'core/components/Tag';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import isNil from 'lodash/isNil';

type Props = {
  id?: string | null;
  label?: string;
  handleRemove?: (key?: string | null) => void;
};

export default function Pill({
  id,
  label,
  handleRemove,
}: Props) {
  return (
    <Tag
      key={id}
      className="flex items-center gap-1 rounded-full h-4 w-fit"
      defaultColor="bg-gray-200"
    >
      <span className="max-w-[150px] truncate">
        {label}
      </span>
      {!isNil(handleRemove) && (
        <IconButton
          className="!p-0"
          onClick={(event) => {
            event.stopPropagation();
            handleRemove?.(id);
          }}
          onMouseDown={(event) => {
            event.stopPropagation();
          }}
        >
          <ClearIcon className="!w-4 !h-4 !text-gray-400" />
        </IconButton>
      )}
    </Tag>
  );
}
