import {
  PatientDTO,
} from 'dtos';
import {
  formatDate,
} from 'utils/date';
import {
  getFullName,
} from 'utils/string';
import {
  PatientCompact,
} from 'pages/Dashboard/pages/Encounters/types/patient';
import {
  Patient,
} from 'pages/Dashboard/types/patient';
import {
  SearchPatientResultDTO,
} from 'dtos/searchPatientResultDTO';
import {
  getAge,
} from 'utils/misc';

type AdditionalProps = Pick<PatientCompact, 'dateOfBirth' | 'formattedDateOfBirth' | 'fullName' | 'age'>;
export const formatPatient = (patient: PatientDTO): PatientDTO & AdditionalProps => ({
  ...patient,
  dateOfBirth: formatDate(patient?.dateOfBirth ?? ''),
  formattedDateOfBirth: formatDate(patient?.dateOfBirth ?? ''),
  fullName: getFullName(patient),
  age: getAge(patient?.dateOfBirth ?? '')?.value,
});

export const formatSearchPatient = (
  patient: SearchPatientResultDTO,
): Patient => ({
  ...patient,
  dateOfBirth: formatDate(patient?.dateOfBirth ?? ''),
  formattedDateOfBirth: formatDate(patient?.dateOfBirth ?? ''),
  fullName: getFullName(patient),
  firstName: patient.firstName ?? undefined,
});
