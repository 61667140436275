import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import {
  SvgIconProps,
} from '@mui/material/SvgIcon/SvgIcon';

export default function PopulateLogo(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 146 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16.0887" cy="9.39143" r="1.0301" fill="currentColor" />
      <circle cx="20.7254" cy="6.81721" r="1.0301" fill="currentColor" />
      <circle cx="20.7254" cy="17.6336" r="1.0301" fill="currentColor" />
      <circle cx="1.15022" cy="17.6336" r="1.0301" fill="currentColor" />
      <circle cx="10.9383" cy="23.2977" r="1.0301" fill="currentColor" />
      <circle cx="10.9373" cy="1.15119" r="1.0301" fill="currentColor" />
      <circle cx="1.15022" cy="6.81721" r="1.0301" fill="currentColor" />
      <path d="M1.15039 17.632L16.089 9.39119M1.15039 17.632L10.9377 23.2976M1.15039 17.632V6.81594M16.089 9.39119L20.7251 6.81594M16.089 9.39119L20.7251 17.632M16.089 9.39119L10.9377 23.2976M16.089 9.39119L10.9377 1.15039M16.089 9.39119L1.15039 6.81594M20.7251 6.81594V17.632M20.7251 6.81594L10.9377 1.15039M20.7251 17.632L10.9377 23.2976M1.15039 6.81594L10.9377 1.15039" stroke="currentColor" strokeWidth="0.360535" />
      <path d="M36.4515 18.8747C35.4941 18.8747 34.6422 18.6213 33.896 18.1145C33.1638 17.6076 32.5725 16.9953 32.1219 16.2773V23.1614H31.1504V7.68263H32.0374V9.89992C32.488 9.19602 33.0864 8.61882 33.8326 8.16832C34.593 7.71782 35.3885 7.49258 36.2192 7.49258C36.9936 7.49258 37.6976 7.65447 38.3312 7.97827C38.9648 8.30206 39.5139 8.73144 39.9786 9.26641C40.4573 9.80137 40.8234 10.4138 41.0768 11.1036C41.3443 11.7793 41.4781 12.4762 41.4781 13.1942C41.4781 14.2078 41.2669 15.151 40.8445 16.0239C40.4221 16.8826 39.8307 17.5725 39.0704 18.0933C38.3242 18.6142 37.4512 18.8747 36.4515 18.8747ZM36.2192 18.0089C36.881 18.0089 37.4723 17.8751 37.9933 17.6076C38.5143 17.3261 38.9578 16.9601 39.3239 16.5096C39.704 16.045 39.9927 15.5241 40.1898 14.9469C40.401 14.3697 40.5066 13.7855 40.5066 13.1942C40.5066 12.5607 40.3939 11.9553 40.1687 11.3781C39.9434 10.8009 39.6195 10.2871 39.1971 9.83657C38.7888 9.38607 38.3171 9.03412 37.7821 8.78071C37.2471 8.51323 36.6698 8.37949 36.0502 8.37949C35.656 8.37949 35.2406 8.46396 34.8042 8.6329C34.3677 8.78776 33.9523 9.00596 33.5581 9.28752C33.1638 9.56909 32.833 9.89288 32.5654 10.2589C32.312 10.6109 32.1642 10.991 32.1219 11.3992V15.0314C32.3613 15.5804 32.6851 16.0802 33.0934 16.5307C33.5158 16.9812 33.9946 17.3402 34.5296 17.6076C35.0787 17.8751 35.6419 18.0089 36.2192 18.0089Z" fill="currentColor" />
      <path d="M53.0588 18.8747C52.2985 18.8747 51.5945 18.7268 50.9468 18.4312C50.2991 18.1356 49.7289 17.7273 49.2361 17.2064C48.7574 16.6715 48.3843 16.0591 48.1167 15.3692C47.8492 14.6794 47.7155 13.9544 47.7155 13.1942C47.7155 12.4199 47.8492 11.6949 48.1167 11.0191C48.3983 10.3293 48.7785 9.72394 49.2572 9.20306C49.75 8.66809 50.3203 8.25279 50.9679 7.95715C51.6156 7.64743 52.3126 7.49258 53.0588 7.49258C53.8192 7.49258 54.5232 7.64743 55.1708 7.95715C55.8185 8.25279 56.3817 8.66809 56.8604 9.20306C57.3532 9.72394 57.7334 10.3293 58.0009 11.0191C58.2825 11.6949 58.4233 12.4199 58.4233 13.1942C58.4233 13.9544 58.2896 14.6794 58.022 15.3692C57.7545 16.0591 57.3744 16.6715 56.8816 17.2064C56.3888 17.7273 55.8185 18.1356 55.1708 18.4312C54.5232 18.7268 53.8192 18.8747 53.0588 18.8747ZM48.7081 13.2364C48.7081 14.1093 48.8982 14.9117 49.2783 15.6438C49.6726 16.3617 50.2006 16.9389 50.8623 17.3754C51.5241 17.7977 52.2563 18.0089 53.0588 18.0089C53.8473 18.0089 54.5724 17.7907 55.2342 17.3542C55.91 16.9178 56.4451 16.3336 56.8393 15.6015C57.2336 14.8554 57.4307 14.0459 57.4307 13.1731C57.4307 12.3002 57.2336 11.5048 56.8393 10.7868C56.4451 10.0548 55.9171 9.47054 55.2553 9.03412C54.5936 8.5977 53.8614 8.37949 53.0588 8.37949C52.2703 8.37949 51.5452 8.60474 50.8835 9.05524C50.2217 9.49166 49.6937 10.0759 49.2995 10.808C48.9052 11.54 48.7081 12.3495 48.7081 13.2364Z" fill="currentColor" />
      <path d="M70.7114 18.8747C69.754 18.8747 68.9021 18.6213 68.1559 18.1145C67.4237 17.6076 66.8324 16.9953 66.3818 16.2773V23.1614H65.4103V7.68263H66.2973V9.89992C66.7479 9.19602 67.3463 8.61882 68.0925 8.16832C68.8528 7.71782 69.6484 7.49258 70.4791 7.49258C71.2535 7.49258 71.9575 7.65447 72.5911 7.97827C73.2247 8.30206 73.7738 8.73144 74.2385 9.26641C74.7172 9.80137 75.0833 10.4138 75.3367 11.1036C75.6042 11.7793 75.738 12.4762 75.738 13.1942C75.738 14.2078 75.5268 15.151 75.1044 16.0239C74.682 16.8826 74.0906 17.5725 73.3303 18.0933C72.5841 18.6142 71.7111 18.8747 70.7114 18.8747ZM70.4791 18.0089C71.1409 18.0089 71.7322 17.8751 72.2532 17.6076C72.7741 17.3261 73.2177 16.9601 73.5837 16.5096C73.9639 16.045 74.2525 15.5241 74.4497 14.9469C74.6609 14.3697 74.7665 13.7855 74.7665 13.1942C74.7665 12.5607 74.6538 11.9553 74.4285 11.3781C74.2033 10.8009 73.8794 10.2871 73.457 9.83657C73.0487 9.38607 72.577 9.03412 72.042 8.78071C71.5069 8.51323 70.9297 8.37949 70.3101 8.37949C69.9159 8.37949 69.5005 8.46396 69.064 8.6329C68.6276 8.78776 68.2122 9.00596 67.818 9.28752C67.4237 9.56909 67.0928 9.89288 66.8253 10.2589C66.5719 10.6109 66.424 10.991 66.3818 11.3992V15.0314C66.6212 15.5804 66.945 16.0802 67.3533 16.5307C67.7757 16.9812 68.2544 17.3402 68.7895 17.6076C69.3386 17.8751 69.9018 18.0089 70.4791 18.0089Z" fill="currentColor" />
      <path d="M82.6301 13.9966V7.68263H83.6016V13.8699C83.6016 15.2496 83.8339 16.2843 84.2985 16.9741C84.7773 17.664 85.4883 18.0089 86.4317 18.0089C87.0512 18.0089 87.6496 17.8681 88.2269 17.5865C88.8182 17.305 89.3322 16.9108 89.7686 16.404C90.2192 15.8831 90.5501 15.2918 90.7613 14.6301V7.68263H91.7328V17.3331C91.7328 17.5021 91.768 17.6358 91.8384 17.7343C91.9088 17.8188 92.0144 17.8611 92.1552 17.8611V18.6635C92.0285 18.6776 91.9299 18.6846 91.8595 18.6846C91.7891 18.6846 91.7258 18.6776 91.6695 18.6635C91.4442 18.6213 91.2541 18.5157 91.0992 18.3467C90.9584 18.1778 90.881 17.9737 90.8669 17.7343V16.045C90.3882 16.9178 89.7264 17.6076 88.8816 18.1145C88.0368 18.6213 87.1286 18.8747 86.1571 18.8747C85.0026 18.8747 84.1225 18.4664 83.5171 17.6499C82.9257 16.8193 82.6301 15.6015 82.6301 13.9966Z" fill="currentColor" />
      <path d="M99.652 3.24805H100.602V16.5729C100.602 17.0516 100.715 17.3965 100.94 17.6076C101.166 17.8047 101.454 17.9033 101.806 17.9033C102.017 17.9033 102.236 17.8822 102.461 17.8399C102.7 17.7977 102.912 17.7414 103.095 17.671L103.306 18.4523C103.066 18.5368 102.778 18.6142 102.44 18.6846C102.102 18.755 101.806 18.7902 101.553 18.7902C100.976 18.7902 100.511 18.6142 100.159 18.2623C99.821 17.9103 99.652 17.4317 99.652 16.8263V3.24805Z" fill="currentColor" />
      <path d="M108.477 15.4959C108.477 14.8484 108.667 14.2852 109.047 13.8066C109.442 13.3138 109.984 12.9337 110.674 12.6663C111.364 12.3988 112.159 12.265 113.06 12.265C113.638 12.265 114.229 12.3143 114.834 12.4129C115.454 12.4973 116.003 12.6311 116.482 12.8141V11.7371C116.482 10.7094 116.179 9.88584 115.574 9.26641C114.982 8.64697 114.173 8.33726 113.145 8.33726C112.596 8.33726 112.025 8.44988 111.434 8.67513C110.843 8.90038 110.237 9.23121 109.618 9.66763L109.216 8.94965C109.92 8.471 110.603 8.11201 111.265 7.87268C111.927 7.61928 112.582 7.49258 113.229 7.49258C114.525 7.49258 115.552 7.87972 116.313 8.65401C117.073 9.4283 117.453 10.4771 117.453 11.8005V17.3331C117.453 17.5161 117.488 17.6499 117.559 17.7343C117.629 17.8188 117.735 17.8611 117.876 17.8611V18.6635C117.763 18.6776 117.664 18.6846 117.58 18.6846C117.51 18.6846 117.446 18.6776 117.39 18.6635C117.151 18.6353 116.96 18.5298 116.82 18.3467C116.679 18.1637 116.608 17.9666 116.608 17.7555L116.587 16.8474C116.095 17.495 115.447 17.9948 114.644 18.3467C113.842 18.6987 112.997 18.8747 112.11 18.8747C111.42 18.8747 110.8 18.7268 110.251 18.4312C109.702 18.1356 109.266 17.7343 108.942 17.2275C108.632 16.7067 108.477 16.1295 108.477 15.4959ZM116.08 16.404C116.221 16.235 116.32 16.0661 116.376 15.8972C116.447 15.7282 116.482 15.5804 116.482 15.4537V13.5743C115.975 13.3772 115.44 13.2294 114.877 13.1308C114.313 13.0323 113.736 12.983 113.145 12.983C112.032 12.983 111.131 13.2083 110.441 13.6588C109.751 14.0952 109.407 14.6935 109.407 15.4537C109.407 15.9183 109.526 16.3547 109.766 16.763C110.005 17.1571 110.343 17.4809 110.779 17.7343C111.23 17.9737 111.737 18.0933 112.3 18.0933C113.103 18.0933 113.842 17.9385 114.518 17.6288C115.207 17.319 115.728 16.9108 116.08 16.404Z" fill="currentColor" />
      <path d="M129.856 18.1356C129.8 18.1637 129.666 18.2341 129.455 18.3467C129.244 18.4594 128.976 18.565 128.652 18.6635C128.342 18.748 127.99 18.7902 127.596 18.7902C127.216 18.7902 126.864 18.7198 126.54 18.579C126.216 18.4242 125.956 18.206 125.759 17.9244C125.562 17.6288 125.463 17.2768 125.463 16.8685V8.48508H123.921V7.68263H125.463V3.92379H126.435V7.68263H129.011V8.48508H126.435V16.6574C126.463 17.0516 126.611 17.3542 126.878 17.5654C127.16 17.7766 127.476 17.8822 127.828 17.8822C128.265 17.8822 128.645 17.8047 128.969 17.6499C129.307 17.495 129.497 17.4035 129.539 17.3754L129.856 18.1356Z" fill="currentColor" />
      <path d="M140.557 18.8747C139.796 18.8747 139.085 18.7268 138.424 18.4312C137.776 18.1215 137.206 17.7062 136.713 17.1853C136.22 16.6503 135.833 16.0379 135.551 15.3481C135.284 14.6442 135.15 13.9051 135.15 13.1308C135.15 12.0891 135.382 11.1458 135.847 10.3011C136.326 9.45646 136.966 8.78072 137.769 8.27391C138.586 7.75302 139.501 7.49258 140.515 7.49258C141.528 7.49258 142.436 7.75302 143.239 8.27391C144.056 8.79479 144.696 9.48462 145.161 10.3434C145.64 11.1881 145.879 12.1242 145.879 13.1519C145.879 13.2223 145.872 13.2998 145.858 13.3842C145.858 13.4546 145.858 13.5109 145.858 13.5532H136.164C136.22 14.4119 136.452 15.1862 136.861 15.876C137.283 16.5518 137.818 17.0938 138.466 17.5021C139.128 17.8962 139.839 18.0933 140.599 18.0933C141.373 18.0933 142.099 17.8962 142.774 17.5021C143.464 17.1079 143.95 16.594 144.232 15.9605L145.098 16.1928C144.872 16.6996 144.534 17.1571 144.084 17.5654C143.633 17.9737 143.098 18.2975 142.479 18.5368C141.873 18.762 141.233 18.8747 140.557 18.8747ZM136.143 12.8141H144.95C144.893 11.9272 144.661 11.1458 144.253 10.4701C143.844 9.79433 143.316 9.26641 142.669 8.8863C142.021 8.49212 141.303 8.29502 140.515 8.29502C139.74 8.29502 139.029 8.49212 138.381 8.8863C137.734 9.26641 137.213 9.80137 136.819 10.4912C136.424 11.1669 136.199 11.9412 136.143 12.8141Z" fill="currentColor" />
    </SvgIcon>
  );
}
