import {
  LoggedInUserInfoDTO,
} from 'dtos/loggedInUserInfoDTO';
import {
  getLoggedUserInfo,
} from 'pages/Dashboard/services/api';
import usePrefetchedData from 'pages/Dashboard/hooks/usePrefetchedData';

export default function useUserInfo(): LoggedInUserInfoDTO | null {
  const { data, isError } = usePrefetchedData<LoggedInUserInfoDTO>({
    key: 'logged-user-info',
    fetchFn: getLoggedUserInfo,
  });

  if (isError) {
    throw Error('Failed to retrieve logged-in user information. Please try again later.');
  }

  return data ?? null;
}
