import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import {
  SvgIconProps,
} from '@mui/material/SvgIcon/SvgIcon';

export default function NotificationIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.3732 6.64118C5.63516 4.2836 7.62791 2.5 10 2.5V2.5C12.3721 2.5 14.3648 4.2836 14.6268 6.64118L14.8366 8.5297C14.9443 9.4985 15.2596 10.4327 15.7611 11.2685L16.2428 12.0714C16.5455 12.5759 16.6969 12.8281 16.7246 13.0315C16.7854 13.4772 16.5412 13.9087 16.1277 14.0858C15.939 14.1667 15.6448 14.1667 15.0565 14.1667H4.94353C4.35515 14.1667 4.06097 14.1667 3.87233 14.0858C3.45885 13.9087 3.21456 13.4772 3.27537 13.0315C3.30311 12.8281 3.45447 12.5759 3.75718 12.0714L4.23888 11.2685C4.74039 10.4327 5.05572 9.4985 5.16337 8.5297L5.3732 6.64118Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.2"
      />
      <path
        d="M6.66671 14.1667C6.66671 14.6044 6.75293 15.0379 6.92044 15.4423C7.08796 15.8467 7.33349 16.2142 7.64302 16.5237C7.95255 16.8332 8.32001 17.0787 8.72443 17.2463C9.12885 17.4138 9.5623 17.5 10 17.5C10.4378 17.5 10.8712 17.4138 11.2757 17.2463C11.6801 17.0787 12.0475 16.8332 12.3571 16.5237C12.6666 16.2142 12.9121 15.8467 13.0796 15.4423C13.2472 15.0379 13.3334 14.6044 13.3334 14.1667"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}
