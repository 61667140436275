import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import {
  SvgIconProps,
} from '@mui/material/SvgIcon/SvgIcon';

export default function TemplateIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4960_32441)">
        <path d="M6.99967 1.33536C6.54964 1.34146 6.27947 1.36751 6.06102 1.47882C5.81014 1.60665 5.60616 1.81063 5.47833 2.06151C5.36703 2.27996 5.34097 2.55013 5.33487 3.00016M12.9997 1.33536C13.4497 1.34146 13.7199 1.36751 13.9383 1.47882C14.1892 1.60665 14.3932 1.81063 14.521 2.06151C14.6323 2.27996 14.6584 2.55013 14.6645 3.00015M14.6645 9.00016C14.6584 9.45019 14.6323 9.72036 14.521 9.93882C14.3932 10.1897 14.1892 10.3937 13.9383 10.5215C13.7199 10.6328 13.4497 10.6589 12.9997 10.665M14.6663 5.33349V6.66683M9.33304 1.3335H10.6663M3.46634 14.6668H8.53301C9.27974 14.6668 9.65311 14.6668 9.93833 14.5215C10.1892 14.3937 10.3932 14.1897 10.521 13.9388C10.6663 13.6536 10.6663 13.2802 10.6663 12.5335V7.46683C10.6663 6.72009 10.6663 6.34672 10.521 6.06151C10.3932 5.81063 10.1892 5.60665 9.93833 5.47882C9.65311 5.3335 9.27974 5.3335 8.53301 5.3335H3.46634C2.7196 5.3335 2.34624 5.3335 2.06102 5.47882C1.81014 5.60665 1.60616 5.81063 1.47833 6.06151C1.33301 6.34672 1.33301 6.72009 1.33301 7.46683V12.5335C1.33301 13.2802 1.33301 13.6536 1.47833 13.9388C1.60616 14.1897 1.81014 14.3937 2.06102 14.5215C2.34624 14.6668 2.7196 14.6668 3.46634 14.6668Z" stroke="#6F767F" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_4960_32441">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
