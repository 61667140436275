// seconds delays before reconnect attempts for SignalR.
// each time one failed to connect use the next delay in ms
// to reconnect.
const DEFAULT_RETRY_DELAYS_IN_MILLISECONDS: number[] = [
  0,
  2000,
  10000,
  2000,
  30000,
  2000,
  40000,
  2000,
  50000,
  2000,
  75000,
  2000,
  120000, // 2 minutes
  2000];

export const signalRConfig = {
  signalRPrefix: 'signalR',
  isLogEnabled: false,
  debugging: {
    isEnabledOnSubscribe: false,
    isEnabledOnPublish: false,
    isSkipAllSubscription: false,
    isAppLevelSubscription: false,
  },
  defaultWaitMs: 300,
  retry: DEFAULT_RETRY_DELAYS_IN_MILLISECONDS,
};
