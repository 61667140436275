import clsx from 'clsx';
import {
  OpUnitType,
} from 'dayjs';
import map from 'lodash/map';
import reject from 'lodash/reject';
import capitalize from 'lodash/capitalize';
import isNil from 'lodash/isNil';
import {
  CreateAppointmentRequestDTO,
  BlockedTimeSlotDTO,
  RecurrenceTypes,
  AddressDTO,
  DoctorsAppointmentSummaryDTO,
  ProviderAvailabilityDTO,
} from 'dtos';
import {
  getFullName,
} from 'utils/string';
import dayjs, {
  Dayjs,
} from 'utils/dayjs';
import {
  getColorByName,
} from 'utils/colors';
import {
  formatISODateTime,
} from 'utils/date';
import {
  CreateEventFormData,
  CalendarEvent,
  CalendarViewEvent,
  CalendarTimelineEvent,
  EventType,
} from 'pages/Dashboard/pages/Appointments/pages/List/types/appointment';
import {
  NormalizedUser,
} from 'pages/Dashboard/pages/Appointments/pages/List/types/event';
import {
  isEmptyNumber,
} from 'utils/misc';

type DefaultAppointmentDataProps = {
  date: Dayjs;
  doctor?: NormalizedUser;
  officeLocation?: AddressDTO;
};

type AppointmentParams = Omit<CreateEventFormData, 'eventDateRange' | 'doctor' | 'recurrence'> & { start: Dayjs };

type RecurringEventParams = Pick<CreateEventFormData, 'recurrence' | 'officeLocation'> &
  { start: Dayjs; recurrenceType: RecurrenceTypes };

export const DEFAULT_APPOINTMENT_DURATION: number = 15;

export type CalendarViewType = 'Daily' | 'Weekly' | 'Monthly' | 'Yearly';

export const DATE_UNIT_BY_VIEW_TYPE: Record<CalendarViewType, Partial<OpUnitType>> = Object.freeze({
  Daily: 'day',
  Weekly: 'week',
  Monthly: 'month',
  Yearly: 'year',
});

export const eventRecurrenceMap: Record<string, string> = {
  daily: 'Day',
  weekly: 'Week',
};

type RequestPayloadType = CreateAppointmentRequestDTO
  & BlockedTimeSlotDTO
  & ProviderAvailabilityDTO;

export function normalizeDailyBreakdown(
  summary: DoctorsAppointmentSummaryDTO[],
): CalendarTimelineEvent[] {
  const events: CalendarTimelineEvent[] = [];
  summary.forEach(({ userId = 0, dailyAppointmentBreakdowns: breakdowns }) => {
    breakdowns?.forEach(({ officeLocationIds, date, appointmentTypesCount: counts }) => {
      officeLocationIds?.forEach((officeLocationId) => {
        events.push({
          userId,
          count: 0,
          typeOfVisitDescription: 'office',
          officeLocationId,
          isTimeAvailability: true,
          allDay: true,
          start: dayjs(date).startOf('day').toDate(),
          end: dayjs(date).endOf('day').toDate(),
          resource: userId,
          title: 'Office',
        });
      });
      counts?.forEach(({ count = 0, typeOfVisitDescription }) => {
        if (typeOfVisitDescription !== 'Time Available') {
          events.push({
            userId,
            count,
            isTimeBlock: typeOfVisitDescription === 'Time Blocked',
            typeOfVisitDescription: typeOfVisitDescription ?? '',
            // mobiscroll event properties below
            allDay: true,
            start: dayjs(date).startOf('day').toDate(),
            end: dayjs(date).endOf('day').toDate(),
            resource: userId,
            title: `${count} - ${typeOfVisitDescription}`,
          });
        }
      });
    });
  });

  return events;
}

function getAppointmentPayload({
  insurance,
  start,
  patient,
  reason,
  typeOfVisitDescription,
  canBeRescheduledSooner,
  officeLocation,
  referringProvider,
  isOutOfPocket,
}: AppointmentParams): CreateAppointmentRequestDTO {
  return {
    insuranceId: isEmptyNumber(insurance?.insuranceId) ? null : insurance?.insuranceId,
    startDateTime: formatISODateTime(start),
    patientId: patient?.patientId ?? 0,
    reasonForVisit: reason,
    typeOfVisitDescription,
    canBeRescheduledSooner,
    officeLocationId: officeLocation?.addressId,
    referringProvider,
    isOutOfPocket,
  };
}

function getBaseRecurringEventPayload({
  recurrenceType: type,
  start,
}: Partial<RecurringEventParams>): Partial<ProviderAvailabilityDTO> {
  return {
    type,
    startDateTime: formatISODateTime(start ?? ''),
  };
}

function getRecurrenceDataForEventPayload({
  recurrence,
  recurrenceType,
}: Partial<RecurringEventParams>): Partial<ProviderAvailabilityDTO> {
  return /^single$/i.test(recurrenceType ?? '') ? {} : {
    interval: Number(recurrence?.interval ?? 0),
    ...(recurrenceType === 'Weekly' ? { repeatsOn: map(recurrence?.repeatsOn, 'id') } : {}),
    ...(recurrence?.endsOn ? { endsOn: formatISODateTime(dayjs(recurrence?.endsOn).endOf('day')) } : {}),
    ...(recurrence?.count ? { count: Number(recurrence?.count) } : {}),
  };
}

function getRecurringEventPayload(
  data: Partial<RecurringEventParams>,
): Partial<ProviderAvailabilityDTO> {
  const { recurrenceType: type, start, recurrence } = data;
  const recurrenceType = capitalize(
    recurrence?.repeatType?.id?.toString() ?? 'single',
  ) as RecurrenceTypes;
  const payloadParams = {
    ...data,
    recurrenceType,
  };
  return {
    type,
    startDateTime: formatISODateTime(start ?? ''),
    ...getBaseRecurringEventPayload(payloadParams),
    ...getRecurrenceDataForEventPayload(payloadParams),
  };
}

function getAvailabilityPayload(
  params: Partial<RecurringEventParams>,
): ProviderAvailabilityDTO {
  return {
    ...getRecurringEventPayload(params),
    officeLocationId: params.officeLocation?.addressId,
  };
}

function getTimeBlockPayload(
  params: Pick<AppointmentParams, 'reason'> & Partial<RecurringEventParams>,
): BlockedTimeSlotDTO {
  const { reason } = params;
  return {
    ...getRecurringEventPayload(params),
    reasonForBlock: reason,
  };
}

const payloadFunctionsByType = Object.freeze([
  getAppointmentPayload,
  getTimeBlockPayload,
  getAvailabilityPayload,
]);

export function serializeAppointmentFormData(
  {
    doctor,
    eventDateRange,
    ...formData
  }: CreateEventFormData,
  appointmentType: number,
): RequestPayloadType {
  const [start, end] = eventDateRange;
  const durationInMinutes = end?.diff(start, 'minutes');
  return {
    ...(payloadFunctionsByType[appointmentType]({
      start,
      ...formData,
    })),
    userId: doctor?.id ?? 0,
    durationInMinutes,
  };
}

export function getDefaultEventFormData({
  date,
  doctor,
  officeLocation,
}: DefaultAppointmentDataProps): CreateEventFormData {
  return {
    patient: { dateOfBirth: '' },
    doctor,
    eventDateRange: [date, date.add(DEFAULT_APPOINTMENT_DURATION, 'minutes')],
    typeOfVisitDescription: '',
    reason: '',
    canBeRescheduledSooner: false,
    officeLocation,
  };
}

export function getAppointmentPlaceholderData({ date }: DefaultAppointmentDataProps) {
  return {
    title: '*',
    start: date.toDate(),
    end: date.add(DEFAULT_APPOINTMENT_DURATION, 'minute').toDate(),
    color: getColorByName('gray-300'),
    placeholder: true,
  };
}

const titlesByType: Record<EventType, (e: CalendarEvent) => string> = Object.freeze({
  appointment: ({ patient }: CalendarEvent) => getFullName(patient ?? {}),
  timeBlock: ({ reason }: CalendarEvent) => reason ?? '--',
  availability: ({ officeLocation }: CalendarEvent) => officeLocation?.nickName ?? '--',
});

export const eventTypeTitles: Readonly<Record<EventType, string>> = Object.freeze({
  appointment: 'Appointment',
  timeBlock: 'Time Block',
  availability: 'Availability',
});

function getEventTitle({ typeName, ...event }: CalendarEvent) {
  return titlesByType[typeName ?? 'appointment'](event);
}

export function convertToCalendarViewEvents(
  calendarEvents: CalendarEvent[],
  filteredIdSet: Set<string>,
): CalendarViewEvent[] {
  return ((reject(
    calendarEvents,
    ({ isTimeAvailability, isDefault }) => isTimeAvailability && isDefault,
  ) ?? []) as CalendarEvent[]).map(
    (event) => {
      const {
        id,
        userId,
        isTimeBlock,
        start,
        end,
        patient,
        color,
        cssClass,
        recurrence,
        isTimeAvailability,
        officeLocation,
      } = event;

      return {
        id,
        patientId: patient?.patientId ?? 0,
        isTimeBlock: Boolean(isTimeBlock),
        isTimeAvailability: Boolean(isTimeAvailability),
        resource: userId,
        start: (start as Dayjs).toDate(),
        end: (end as Dayjs).toDate(),
        title: getEventTitle(event),
        color,
        cssClass: clsx(cssClass, {
          rounded: isTimeAvailability,
          'is-filtered-out': !filteredIdSet.has(id) && !(isTimeBlock || isTimeAvailability),
        }),
        ...(!isNil(recurrence) ? ({
          recurrence: {
            ...(recurrence ?? {}),
            ...(!isNil(recurrence?.endsOn)
              ? { until: recurrence?.endsOn }
              : {}
            ),
          },
        }) : {}),
        editable: isNil(recurrence),
        officeLocation,
      };
    },
  );
}
