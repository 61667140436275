import {
  useQuery,
} from 'react-query';
import {
  ToDoCountResponseDTO,
} from 'dtos';
import {
  getApiToDoGetUnreadToDoCountOfUser,
} from 'endpoints';

export function useUserToDosCount() {
  return useQuery<ToDoCountResponseDTO | void, Error>(
    ['user-to-dos-count'],
    getApiToDoGetUnreadToDoCountOfUser,
  );
}
